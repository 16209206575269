@charset 'UTF-8';

// 1. Configuration and helpers
@import 'abstracts/variables',
'abstracts/functions',
'abstracts/mixins';

//================== Popup ==================//

.popup-wrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;

  &.show {
    display: block;
  }

  // POPUP
  .popup {
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 63.3rem;
    max-height: 90vh;
    border-radius: 1rem;
    background-color: $color-white;
    transform: translate(calc(-50% + 0.5px), calc(-50% + 0.5px));

    &.no-scroll {
      overflow:visible;

      .popup-container {
        overflow-x: visible;
        overflow-y: visible;
      }
    }
    // CONTAINER
    .popup-container {
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 90vh;
      margin-right: 0.8rem;
    }

    &.wide-padding {
      .popup-content {
        padding: 5.2rem 9.2rem;
      }
    }
    .form-guide {
      margin-bottom:1.5rem;

      + p {
        margin-top: 1.2rem;
      }
    }

    .tablist-content {
      .tab-group {
        &.type-default {
          margin-bottom: 3rem;
          border-bottom: 1px solid $color-border-05;
          .tab {
            a {
              height: 3.6rem;
              @include set-font(1.6rem, 500, $color-gray-02, 1.1);
            }
            &.active {
              a {
                @include set-font(1.6rem, 700, $color-gray-01, 1.1);
              }
            }
          }
        }
      }
      .tab-panel {
        .data-state {
          padding-bottom: 1.6rem;
          @include set-flex(row, space-between, center);
          .col {
            font-size: 0;
            .capacity-info {
              display: inline-block;
              @include set-font(1.4rem, normal, $color-gray-02, 1.4);
              vertical-align: middle;
              + .total-count {
                padding-left: 1.7rem;
                &:after {
                  content:"";
                  position: absolute;
                  left: 0.8rem;
                  top: 50%;
                  width: 1px;
                  height: 1.2rem;
                  background-color: rgba(0, 0, 0, 0.1);
                  transform: translateY(-50%);
                }
              }
            }
            .total-count {
              position: relative;
              display: inline-block;
              @include set-font(1.4rem, normal, $color-gray-01, 1.4);
              vertical-align: middle;
              strong {
                margin-left: 0.6rem;
                font-weight: bold;
              }
              + .total-count {
                padding-left: 1.7rem;
                &:after {
                  content:"";
                  position: absolute;
                  left: 0.8rem;
                  top: 50%;
                  width: 1px;
                  height: 1.2rem;
                  background-color: rgba(0, 0, 0, 0.1);
                  transform: translateY(-50%);
                }
              }
            }
          }
        }
        .btn-data-delete {
          padding: 0;
          width: 3.6rem;
          height: 3.6rem;
          border: 1px solid $color-border-05;
          border-radius: 100%;
          background: asset-image('ic_detele_black.svg') no-repeat center / 2.4rem auto;
          font-size: 0;
        }
        .btn-data-download {
          padding: 0;
          width: 3.6rem;
          height: 3.6rem;
          border: 1px solid $color-border-05;
          border-radius: 100%;
          background: asset-image('ic_download.svg') no-repeat center / 2.4rem auto;
          font-size: 0;
        }
        .btn-data-report {
          padding: 0;
          width: 3.6rem;
          height: 3.6rem;
          border: 1px solid $color-border-05;
          border-radius: 100%;
          background: asset-image('lnb/ic_report_black.svg') no-repeat center / 2.4rem auto;
          font-size: 0;
        }
        .table {
          thead { 
            th {
              padding: 0 1.2rem;
              background-color: $color-gray-04;
              height: 4.4rem;
              border-left: 1px solid rgba(53, 64, 86, 0.1);
              text-align: left;
              @include set-font(1.4rem, 700, #697387, 1.4);
              &:first-child {
                border-left: 0;
              }
              &.center {
                text-align: center;
              }
            }
          }
          tbody {
            td {
              padding: 0.8rem 1.2rem;
              height: 5.4rem;
              border-left: 1px solid rgba(53, 64, 86, 0.1);
              border-top: 1px solid rgba(53, 64, 86, 0.1);
              @include set-font(1.4rem, normal, $color-gray-01, 1.4);
              &:first-child {
                border-left: 0;
              }
              &.center {
                text-align: center;
              }
              .btn-data-edit {
                padding: 0;
                width: 3.6rem;
                height: 3.6rem;
                border: 1px solid $color-border-05;
                border-radius: 100%;
                background: asset-image('ic_edit.svg') no-repeat center / 2.4rem auto;
                font-size: 0;
              }
              .btn-data-download {
                padding: 0;
                width: 3.6rem;
                height: 3.6rem;
                border: 1px solid $color-border-05;
                border-radius: 100%;
                background: asset-image('ic_download.svg') no-repeat center / 2.4rem auto;
                font-size: 0;
              }
            }
          }
        }
      }
    }

    &.middle {
      max-width: 70rem;
    }

    &.pop-middle {
      max-width: 80rem;
    }

    &.large {
      max-width: 102.8rem;
    }

    &.full {
      max-width: 90%;
    }

    &.popup-type {
      max-width: 68rem;
    }

    .field {
      .pop-label {
        margin-bottom: 1.2rem;
      }

      .input-box {
        &.pop-input {
          input {
            color: #757575;
            border: 1px solid $color-border-05;
            border-radius: 6px;
          }
        }
      }
    }

    &.popup-type02 {
      max-width: 86rem;
    }

    .field-type-radio {
      .field-label {
        margin-bottom: 2.4rem;
        font-weight: 400;
      }

      .field-form {
        .field-box {
          .pop-radio {
            margin-bottom: 0;

            &:first-child {
              margin-bottom: 2.4rem;
            }

            label {
              font-size: 1.4rem;
            }

            .field-description {
              color: #757575;
            }
          }
        }
      }
    }

    &.popup-remote-control {
      max-width: 145.8rem;
    }

    &.popup-fulll {
      max-width: 120rem;
    }

    .full-screen {
      position: relative;
      border-radius: 8px;
      overflow: hidden;

      .control-memu {
        position: absolute;
        bottom: 0.8rem;
        right: 0.8rem;
        z-index: 10;

        .btn-icon {
          padding: 0;

          & + .btn-icon {
            margin-left: 0;
          }
        }

        .btn-remote {
          background: asset-image('ic_remote.svg') no-repeat center / 2.7rem auto;
        }
        .btn-refresh {
          background: asset-image('ic_refresh.svg') no-repeat center / 2.7rem auto;
        }
      }
    }

    .popup-header {
      @include set-flex(row, space-between, center);
      padding: 2.4rem 1.6rem 0 2.4rem;

      h1 {
        @include set-font(2rem, 700, #111, 1.3);

        span {
          font-weight: 400;
          color: #757575;
        }
      }
      .sub-title {
        @include set-font(1.6rem, normal, #010101, normal);
        &:before {
          content: '';
          display: inline-block;
          width: 1px;
          height: 1.2rem;
          margin: 0 1.5rem 0 1rem;
          background-color: $color-border-01;
        }
      }
    }

    .popup-content-top {
      @include set-flex(row, space-between, center);
      margin-top: 2.7rem;
      padding: 1.6rem 2rem;
      background-color: $color-content-bg;
  
      .step-title .title {
        @include set-font(1.6rem, 400, $color-gray-01, normal);
  
        span {
          @include set-font(1.6rem, 700, $color-gray-01, normal);
        }
      }
  
      // .step-title .title

      // 웹접근성 2023.12.27
      .step-group {
        @include set-flex(row, flex-end, center);

        .step {
          a {
            @include set-font(1.6rem, 700, $color-gray-03, normal);
            padding: 0;
            display: block;
            width: 2.8rem;
            height: 2.8rem;
            margin-left: .6rem;
            border-radius: 50%;
            border: 1px solid $color-border-03;
            background-color: $color-white;
            text-align: center;
            cursor: default;
          }

          &.active a {
            background-color: #354056;
            color: $color-white;
          }
        }
      }
  
      .tab-group {
        @include set-flex(row, flex-end, center);
  
        .tab {
          a {
            @include set-font(1.6rem, 700, $color-gray-03, normal);
            padding: 0;
            display: block;
            width: 2.8rem;
            height: 2.8rem;
            margin-left: .6rem;
            border-radius: 50%;
            border: 1px solid $color-border-03;
            background-color: $color-white;
            text-align: center;
          }
  
          &.active a {
            background-color: #354056;
            color: $color-white;
          }
  
          &:focus,
          &:hover a {
            border-color: #354056;
            border-width: 2px;
            background-color: $color-border-02;
            color: $color-gray-03;
          }
  
          // &:focus, &:hover
        }
  
        // .tab
      }
    }

    &.popup-modify {
      .popup-content {
        .field-form {
          .field-box {
            .unit {
              @include set-font(1.4rem, 400, $color-gray-01, 140%);
            }
          }
        }
      }
    }

    &.popup-alert-message,
    &.fullpopup {
      left: 0;
      top: 0;
      max-width: none;
      height: 100vh;
      max-height: none;
      border-radius: 0;
      transform: translate(0);
      .popup-container {
        overflow: hidden;
        max-height: none;
      }
      .popup-header {
        @include set-flex(row, center, center);
        padding: 5.4rem 0;
        h1 {
          text-align: center;
          @include set-font(3.2rem, 700, $color-gray-01, 1.3);
        }
      }
      .popup-content {
        overflow: auto;
        margin-bottom: 5.4rem;
        padding: 0;
        height: calc(100vh - 30rem);
        .tablist-content {
          margin: 0 auto;
          width: 75%;
          min-height: 100%;
          border: 1px solid $color-border-05;
          border-radius: 0.8rem;
          .tab-group {
            &.type-default {
              margin: 0;
              padding: 1.6rem 2.4rem 0;
            }
          }
          .tab-panel {
            padding: 2.4rem;
            .alert-guide-txt {
              margin-bottom: 2.4rem;
              @include set-font(1.6rem, normal, $color-gray-01, 1.4);
            }

            .search-box2 {
              text-align: right;
            }
          }
          .field-type-mo {
            .message-type {
              padding: 0 1.2rem;
              background-color: #f5f6fa;
              height: 4.4rem;
              border-left: 1px solid rgba(53, 64, 86, 0.1);
              text-align: left;
              font-size: 1.4rem;
              font-weight: 700;
              color: #697387;
              line-height: 1.4;
              display: flex;
              align-items: center;
            }
            .message-detail {
              .message-item {
                border-bottom: 1px solid #d7d7d7;
                &:last-child {
                  margin-bottom: 4.8rem;
                }
              }
              .message-detail-title-area {
                height: 5.4rem;
                display: flex;
                align-items: center;
                font-size: 1.4rem;
                justify-content: space-between;
                .title {
                  padding: 0 1.2rem;
                }
                .arrow {
                  margin-right: 0.8rem;
                }
              }
              .is-show {
                > div:not(:last-child) {
                  border-bottom: 1px solid #dcdcdc;
                }
                > div {
                  background-color: #E8eBF0;
                  height: 5.4rem;
                  display: flex;
                  align-items: center;
                  padding: 0 1.2rem;
                  font-size: 1.4rem;
                  > :first-child {
                    color: #697387;
                    flex-basis: 50%;
                  }
                  > :last-child {
                    flex-basis: 50%;
                    margin-left: 0.8rem;
                  }
                  .link-target {justify-content: flex-start;}
                }
              }
              .contents:not(.is-show) {
                display: none;
              }
            }
          }
        }
      }
    }

    &.popup-alert-message {
      // .popup-content {
      //   overflow: hidden;
      // }

      table {
        tbody {
          height: 41rem;
          overflow: auto;
        }
      }
    }

    &.popup-add-template {
      width: 80rem;
      max-width: 80rem;
      .popup-content {
        .field {
          .field-form {
            input[type="text"] {
              padding: 0 1.2rem;
              height: 3.6rem;
              border: 1px solid $color-border-05;
              border-radius: 0.6rem;
              @include set-font(1.4rem, 400, $color-gray-01, 1.4);
              // &:focus {
              //   border-color: #1D828F;
              // }
            }
            .word-count {
              position: relative;
              padding: 0.8rem 0.4rem 0 0;
              text-align: right;
              @include set-font(1.4rem, 500, #757575, 1.4);
              .field-description {
                display: inline-block;
                position: absolute;
                top: 2px;
                left: 0;
              }
            }
            .field-guie {
              padding: 0.4rem 0 0 1.2rem;
              @include set-font(1.2rem, 400, #757575 , 1.4);
            }
            .radio {
              display: inline-block;
              margin-left: 2.4rem;
              vertical-align: top;
              &:first-child {
                margin-left: 0;
              }
            }
            textarea {
              padding: 0.8rem;
              height: 14.4rem;
              border: 1px solid $color-border-05;
              border-radius: 0.8rem;
              @include set-font(1.4rem, 400, $color-gray-01 , 1.4);
            }
            input[type="file"] {
              position: absolute;
              left: -3000%;
              + label {
                display: inline-block;
                padding: 0 1.6rem;
                height: 3.6rem;
                line-height: 3.4rem;
                &:active {
                  line-height: 3rem;
                }
              }
            }
          }
        }
      }
    }

    &.popup-select-file {
      width: 80rem;
      max-width: 80rem;
      .popup-content {
        .table-state {
          padding-bottom: 1.6rem;
          text-align: right;
          @include set-font(1.4rem, 400, $color-gray-01, 1.4);
          strong {
            margin-left: 0.6rem;
            font-weight: bold;
          }
        }
        .table {
          thead {
            th {
              padding: 0 1.2rem;
              background-color: #f5f6fa;
              height: 4.4rem;
              border-left: 1px solid rgba(53, 64, 86, 0.1);
              @include set-font(1.4rem, 700, #697387, 1.4);
              &:first-child {
                border-left: 0;
              }
            }
          }
          tbody {
            td {
              padding: 0.8rem 1.2rem;
              height: 5.4rem;
              border-left: 1px solid rgba(53, 64, 86, 0.1);
              border-top: 1px solid rgba(53, 64, 86, 0.1);
              @include set-font(1.4rem, 400, $color-gray-01, 1.4);
              &:first-child {
                border-left: 0;
              }
            }
          }
        }
      }
    }

    &.popup-file-box {
      width: 74.8rem;
      max-width: 74.8rem;
      .popup-content {
        padding-bottom: 0;
        // .field {
        //   .file-standard-info {
        //     padding-top: 1.5rem;
        //     .file-standard {
        //       @include set-font(1.4rem, 400, $color-gray-02, 1.4);
        //     }
        //   } 
        // }
      }
    }

    &.popup-create-role,
    &.popup-modify,
    &.popup-assigned-user,
    &.popup-email-recipients {
      width: 80rem;
      max-width: 80rem;
      .popup-content {
        .selected-state,
        .table-state {
          text-align: right;
          @include set-font(1.4rem, 400, $color-gray-01, 1.4);
          strong {
            margin-left: 0.6rem;
            font-weight: bold;
          }
        }
        .selected-state {
          & + .table-state {
            margin-left: .8rem;
            // padding-left: .8rem;
            &::before {
              content: '';
              margin-right: .8rem;
              display: inline-block;
              width: 1px;
              height: 1rem;
              background-color: $color-border-05;
            }
          }
        }
        .email-recipients-box {
          padding: 2rem;
          border: 1px solid $color-border-03;
          border-radius: .8rem;
          .field-form {
            .field-box {
              input[type="text"] {
                padding: 0 1.2rem;
                height: 3.6rem;
                border: 1px solid $color-border-05;
                border-radius: 0.6rem;
                @include set-font(1.4rem, 400, $color-gray-01, 1.4);
                background-color: #fff;
                &:focus {
                  border-color: #1D828F;
                }
              }
            }
            .email-list {
              margin: 1.6rem 1.4rem 0;
              &.mt0 {margin-top: 0;}
              li {
                @include set-flex(row, flex-start, center);
                margin-top: 1.6rem;
                span {
                  @include set-font(1.4rem, 400, $color-gray-01, 1.4);
                  vertical-align: sub;
                }
                .btn-email-delete {
                  width: 2rem;
                  height: 2rem;
                  box-sizing: content-box;
                  background: asset-image('ic_error_fill.svg') center no-repeat;
                }
                &:first-child {
                  margin-top: 0;
                }
                .checkbox {
                  margin-bottom: 0;
                }
                .role-name {
                  margin-left: .8rem;
                  padding-left: .8rem;
                  border-left: 1px solid $color-border-05;
                  &::before {
                    content: '';
                    display: inline-block;
                    width: 1.8rem;
                    height: 1.8rem;
                    margin-right: .4rem;
                    background: asset-image('ic_licensemanagement.svg') no-repeat center / 1.6rem;
                  }
                  span {
                    vertical-align: top;
                  }
                }
              }
            }
            &.search-emal {
              margin-bottom: 1.6rem;
            }
          }
          &+.field {
            margin-top: 1.2rem;
          }
        }
        .field-type-radio {
          .radio {
            label {
              @include set-font(1.4rem, 400, $color-gray-01, 1.4);
            }
            &+.radio {
              margin-top: 1.6rem;
            }
          }
        }
      }
    }

    &.popup-register {
      .popup-content {
        .form-guide {
          margin-bottom: 1.2rem;
        }
      }
    }
  }
  .popup-content {
    padding: 2.4rem;
    padding-right: 1.6rem;

    .title-box {
      .section-title {
        @include set-font(1.2rem, 700, $color-gray-02, 1.4);
        margin-top: 4.8rem;
        margin-bottom: 1.6rem;
        padding-bottom: .8rem;
        border-bottom: 1px solid $color-border-05;
      }
    }

    .execution-date {
      .input-box {
        @include set-flex(row, space-between, center);
      }
      .datepicker {
        width: calc(100% - 4.6rem);
      }
      .ui-datepicker-trigger {
        padding: 0;
        width: 3.6rem;
        height: 3.6rem;
        border: 1px solid $color-border-05;
        border-radius: 100%;
        font-size: 0;
      }
    }

    .inner-header-box {
      @include set-flex(row, space-between, center);
      margin-bottom: 1.2rem;
      .left-side {
        @include set-flex(row, flex-start, center);
      }
      .right-side {
        @include set-flex(row, flex-end, center);
        .state-box {
          @include set-flex(row, flex-end, center);
        }
      }
    }

    .search-box2 {
      margin-left: 2rem;
      .search-inner-box {
        position: relative;
        font-size: 0;
        &:before {
          content:'';
          position:absolute;
          top:50%;
          transform: translateY(-50%);
          left:1.2rem;
          width: 2rem;
          height: 2rem;
          background: asset-image('ic_search.svg') no-repeat center / 2rem;
        }
        .search_b {
          @include set-font(1.4rem, 400, #757575, 140%);
          padding-left: 4rem;
          width: 24rem;
          height: 3.4rem;
          border: 1px solid #DADDE2;
          border-radius: .6rem;
          &:focus, &:active {

            border: 2px solid $color-primary;

        
            + .btn-input-remove {
              display:block;
              right:1.5rem;
            }
          }
        }
      }
    }

    .info {
      @include set-font(1.4rem, 400, $color-gray-05);
    }

    .text-content {
      @include set-flex(row, center, center);
      height: 10rem;
      p {
        margin: 0;
        @include set-font(1.65rem, 400, $color-gray-01);
        span {
          color: $color-primary;
        }
      }
    }
    .top-box {
      @include set-flex(row, space-between, flex-start);
      margin-bottom: 1rem;

      .right-side {
        flex-grow: 1;
        @include set-flex(row, flex-end, flex-start);
      }
    }

    .filter-container {
      @include set-flex(row, flex-start, flex-start);

      &.space-between {
        @include set-flex(row, space-between, center);
      }
    }

    h2 {
      @include set-font(1.6rem, 400, $color-black, normal);
      margin-bottom: 1rem;
    }

    .data-list-container {
      margin-bottom: 3rem;

      &:last-child {
        margin-bottom:0;
      }
    }

    .field {
      margin-bottom: 2.4rem;
  
      &:last-child {
        margin-bottom: 0;
      }

      &.field-flex {
        .field-label {
          .small-text {
            @include set-font(1.35rem, 400, $color-gray-03);
          }
        } // .field-label

        &.double-line-label {
          @include set-flex(row, flex-start, center);
          .field-label {
            height: 6.6rem;
          }
        }

      }

      .field-form {
        .radio {
          label {
            font-size: 1.4rem;
          }
        }

        .checkbox {
          label {
            font-size: 1.4rem;
          }
        }
      }

      input[type="text"],
      input[type="password"],
      input[type="email"],
      input[type="tel"],
      input[type="number"] {
        height:100%;
      }

      .input-box {
        height:3.8rem;
      }

      .message-target-wrap {
        padding: 1.6rem;
        border: 1px solid $color-border-05;
        border-radius: 0.6rem;
        .empty-txt {
          padding: 2rem 0;
          text-align: center;
          @include set-font(1.6rem, normal, $color-gray-01, 1.4);
        }
        .message-target-info {
          font-size: 0;
          dt {
            display: inline-block;
            margin-top: 1.6rem;
            width: 12rem;
            @include set-font(1.4rem, 500, $color-gray-01, 1.4);
            vertical-align: top;
            &:first-of-type {
              margin-top: 0;
            }
          }
          dd {
            display: inline-block;
            margin-top: 1.6rem;
            padding-left: 0.8rem;
            width: calc(100% - 12rem);
            @include set-font(1.4rem, normal, $color-gray-02, 1.4);
            vertical-align: top;
            &:first-of-type {
              margin-top: 0;
            }
          }
          .message-target {
            font-size: 0;
            .message-target-item {
              display: inline-block;
              margin: 0 0.8rem 0.8rem 0;
              padding: 0.35rem 0.6rem;
              border: 1px solid $color-border-05;
              border-radius: 0.4rem;
              @include set-font(1.2rem, normal, $color-gray-01, 1.4);
              vertical-align: top;
            }
          }
        }
      }
    }

    table {
      .field {
        margin-bottom: 0;

        .radio {
          margin-top: -9px;
          margin-left: 7px;
        }
      }
      
      .convert-table-to-list {
        .radio {
          margin-top: -13px;
        }
      }
    }

    .line-box {
      max-height: 49.5rem;
      overflow-y: auto;
      padding: 1.6rem;
      border: 1px solid $color-border-03;
      border-radius: 5px;
      @include set-font(1.3rem, 400, $color-gray-03, normal);
      word-break: break-all;
    }

    .pop-list-box {
      .field {
        margin-bottom: 1.6rem;
        &:last-child {
          margin-bottom: 0;
        }
  
        .field-label {
          color: $color-gray-02;
          justify-content: flex-start;
          width: 18.7rem;
          height: 3.6rem;
          margin-right: 0;
          padding: 0.75rem 0 0.55rem 0;
        }
  
        .field-content {
          font-size: 1.6rem;
          color: $color-gray-01;
          padding: 0.75rem 0 0.55rem 0;
          height: 3.6rem;
        }
      }
    }

  
  }


  .content-box {
    .content-top {
      padding-bottom:2.5rem;
      .title {
        @include set-font(2.4rem, 700, $color-error, normal);
        margin-bottom:.5rem;
        text-align: center;
      }
    }
  }

  .popup-footer {
    padding: 2.4rem;
    .button-box {
      @include set-flex(row, center, center);
      .btn {
        margin: 0 .6rem;
      }
      &.double-button {
        .btn {
          min-width: 24rem;
        }
      }
    }
  }
  // DIM
  .dim {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .65);
  }
}
.popup-content {

  .txt-group {
    .description {
      margin-bottom: 0;
    }
  }

  .description {
    margin-bottom: 1rem;
    @include set-font(1.6rem, 400, $color-gray-01, 1.4);
    & +.field {
      margin-top: 3rem;
    }
  }
  .account-name {
    @include set-font(1.65rem, bold, $color-primary, 1.36);
    margin-bottom:1.5rem;
    & + .field {
      margin-top: 3rem;
    }
  }
}

.age-group {
  @include set-flex(row, center, center);
  min-height: 13rem;
  background-color: #f6f6f6;
  .field-form {
    .field-box {
      .checkbox {
        margin-bottom: 0;
        & + .checkbox {
          margin-left: 3rem;
        }
      }
    }
  }
}

.popup {
  &.popup-qr-view {
    max-width: 47.4rem;
    .step-guide {
      p {
        margin:0;
        @include set-font(1.35rem, 400, #767676, 2.55rem);
      }
    }
    .image-container {
      margin: 2rem 0 4rem 0;
      height: 24rem;
      text-align: center;
      img {
        width: 24rem;
        height: 100%;
      }
    }
  }
}

.popup {
  &.popup-check-response {
    .field-form {
      .field-box {
        margin: 0;
      }
    }
    .btn-bottom {
      margin-top: 8px;
      text-align: right;
    }
  }
}

.field-type-textarea {
  .btn {
    font-size: 1.2rem;
    height: 2.8rem;
  }
}

.field {
  &.field-set-value {
    .field-title {
      @include set-font(1.2rem, 400, $color-gray-02, normal);
    }
  
    .field-form {
      .field-value {
        margin: 0 0 0 1.2rem;
  
        input {
          padding: 0 0 0 1.2rem;
          height: 3.6rem;
          font-size: 1.4rem;
          color: #757575;
        }
      }
    }
  }

  &.field-exceptions-address {
    .http-box {
      margin-bottom: 2.4rem;
    }
  }

}


.priority-list {
  li {
    @include set-flex(row, space-between, center);
    border-bottom: 1px solid #E8EBF0;
    padding: 1.2rem 0;
    cursor: pointer;
    &:hover {
      background-color: #efefef;
    }

    .priority-con {
      span {
        &:first-child {
          @include set-font(1.6rem, 500, $color-gray-02, normal);
          border-right: 0;
          margin-right: 1.6rem;
        }

        &:nth-child(2) {
          @include set-font(1.6rem, 500, $color-gray-01, normal);
          border-right: 0;
        }

      }
    }

    // .priority-btn {

    // }
  }

  .btn-down,
  .btn-up {
    display: inline-block;
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
    border: 1px solid $color-border-05;

    &:hover {
      border: 1px solid #1D828F;
      background-color: #dcdcdc;
    }
  }

  .btn-down {
    background: asset-image('ic_arrow_down.svg') no-repeat center / 2.4rem;
  }

  .btn-up {
    background: asset-image('ic_arrow_up.svg') no-repeat center / 2.4rem;
  }
}

.txt-box {
  p {
    @include set-font(1.2rem, 400, $color-gray-05, normal);
  }
}

.field-form {
  .txt-box {
    .field-description {
      margin-top: 1.2rem;

      &:first-child {
        margin-top: 0.4rem;
      }
    }
  }
}

// Group Settings
.group-list-container {
  border: 1px solid #dcdcdc;
  border-radius: 1rem;
  background-color: #fff;
  box-sizing: border-box;
  overflow: hidden;

  .devices-menu-site {
    min-height: 30rem;
    & ul {
      height: auto;
      overflow-y: initial;
    }
    & > ul {
      height: 30rem;
      overflow-y: auto;
    }
  }

  .child-menu{
    &.has-child  {
      > .menu-box {
        .group-tit {
          a {
            display: inline-block;
          }
        }
      }
    }

    &.open-menu {
      > .menu-box {
        .group-tit {
          a {
            background: url("../images/ic_arrow_up.svg") no-repeat center/100% auto;
          }
        }
      }
    }

    &.bd-none {
      .menu-box {
        border: 0;
      }
    }

    .child-menu {
      .group-tit {
        margin-left: 2rem;
      }

      .child-menu {
        .group-tit {
          margin-left: 4rem;
        }

        .child-menu {
          .group-tit {
            margin-left: 6rem;
          }
        }
      }
    }
  }

  .menu-box {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    @include set-flex(row, space-between, center);

    .group-tit {
      @include set-font(1.6rem, 400, $color-dark-gray, normal);
      background: url(../images/ic_group.svg) no-repeat 3.6rem 1.9rem/2.2rem;
      padding: 1.8rem 0 1.8rem 6.2rem;
      position: relative;

      input[type="text"] {
        border: 0;
        padding: 0;
      }

      a {
        display: none;
        margin-right: 0.8rem;
        padding: 0;
        width: 1.6rem;
        height: 1.6rem;
        background: url(../images/ic_arrow_down.svg) no-repeat center/100% auto;
        font-size: 0;
        vertical-align: middle;
        position: absolute;
        left: 1.2rem;
        top: 50%;
        transform: translateY(-50%);
        border-radius: .8rem;

        &:hover {
          background-color: #E8EBF0 !important;
        }
        &.active {
          background-color: #E8EBF0 !important;
        }
      }
    }

    .field {
      &.field-type-dropdown {
        margin-right: 1.6rem;
      }

      .dropdown {
        .btn-dropdown {
          border: 0;
          padding: 0;
          background: url(../images/ic_group_btn.svg) no-repeat center;
          background-size: 2.4rem;
          width: 3.6rem;
          height: 3.6rem;
          border-radius: 50%;

          &:hover {
            background-color: #BEC2CB;
          }

          &:focus, &:active {
            background-color: #BEC2CB;
          }

          &::after {
            display: none;
          }

          &[aria-selected=true] {
            + .dropdown-menu {
              right: 0;
              width: 22rem;
            }
          }


        }

        .dropdown-menu {
          left: -10rem;

          .lists {
            .list {
              a {
                padding-right: 1rem;
              }
            }
          }
        }

        &.dropdown-bottom {
          .dropdown-menu {
            top : auto;
            bottom: 0;
          }
        }
      }
    }

  }

}

.popup-wrapper .popup .popup-content {
  .field {
    &.field-type-sort-by {
      margin-top: 2.4rem;

      .field-header {
        .field-title {
          @include set-font(1.2rem, 400, $color-gray-02);
        }
      }
    }
  }
}

.file-standard-info {
  padding-top: 1.5rem;
  .file-standard {
    @include set-font(1.4rem, 400, $color-gray-02, 1.4);
  }
}

.inform-txt-box {
  margin-top: 1.6rem;
  
  .description {
    margin-bottom: 0;
  }
}

// table.priority-table {
//   height: 29.3rem;

//   tbody {
//     tr {
//       th {
//         @include set-font(1.6rem, 500, $color-gray-02, normal);
//         background-color: #fff;
//         border-right: 0;

//         &:hover {
//           background-color: #efefef;
//         }
//       }

//       td {
//         font-size: 1.6rem;
//         font-weight: 500;
//         border-right: 0;
//       }
//     }
//   }

//   .btn-down,
//   .btn-up {
//     display: inline-block;
//     width: 3.6rem;
//     height: 3.6rem;
//     border-radius: 50%;
//     border: 1px solid $color-border-05;

//     &:hover {
//       border: 1px solid #1D828F;
//       background-color: #dcdcdc;
//     }
//   }

//   .btn-down {
//     background: asset-image('ic_arrow_down.svg') no-repeat center / 2.4rem;
//   }

//   .btn-up {
//     background: asset-image('ic_arrow_up.svg') no-repeat center / 2.4rem;
//   }
// }

//150%
@media screen and (max-width:1280px) {
  // Terms of Use , Privacy Terms and Conditions
  .popup-wrapper {
    .popup {
      .popup-content {
        .line-box {
          max-height: 100%;
        }
      }
    }
  }
}

// 200%
@media screen and (max-width: 991px) {
  .popup-wrapper {
    .popup {
      // 큰 팝업 가로 full로 변경
      &.middle, &.large {
        left: 1rem;
        right: 1rem;
        width: auto;
        max-width: 100% !important;
        transform: translate(0 , -50%);
        .popup-content {
          padding: 2rem;
        }
      }

      .popup-content {
        padding: 2rem;
        
        .pop-list-box {
          .field {
            display: block;

            .field-label {
              margin-bottom: 0;
            }
          }
        }
      }

      .popup-footer {
        padding-top: 2rem;
        .button-box {
          .btn {
            // @include set-flex-grow(1)
          }
        }
      }
    }
  }

  .popup-content {
    .field.field-set-value {
      .field-form {
        .field-value {
          position: static !important;
          text-align: right;
          margin-top: 1rem;
        }
      }
    }
  }

  .convert-table-to-list {
    .dropdown {
      .btn-dropdown {
        background-color: #FFF;
      }
    }
  }

}

// 250%
@media screen and (max-width: 767px) {}

// 300%
@media screen and (max-width: 640px) {
  .popup-wrapper {
    .popup {
      .group-list-wrap {
        .inner-header-box {
          flex-direction: row;
        }
      }

      .popup-content {
        padding: 2.4rem;
      }
      // top:1.8rem;
      // transform: translate(calc(-50% + 0.5px), 0);
      // 작은 팝업 가로 full 변경
      &:not(.large) {
        left: 1rem;
        right: 1rem;
        width: auto;
        max-width: 100% !important;
        transform: translate(0 , -50%);
      }
      &.wide-padding {
        .popup-content {
          padding: 4rem 2rem 2rem;
          // text-align: center;
          .field {
            .field-label {
              width: 100%;
              max-width: 18rem;
              margin: 0 auto !important;
            }
          }
        }
      }

      .popup-header {
        align-items: flex-start;
        
        h1 {
          span {
            display: block;
          }
        }
      }

      .popup-footer {
        padding: 0 2.4rem 2.4rem;
        .button-box {
          display: block;
          margin: -0.5rem 0;
          .btn {
            max-width: 100%;
            width: 100%;
            margin: 0.5rem 0;
          }
        }
      }

      .inner-header-box {
        flex-direction: column-reverse;
        align-items: flex-start;
        // .left-side {
        //   @include set-flex(row, flex-start, center);
        // }
        .right-side {
          width: 100%;
          flex-direction: column;
          .state-box {
            margin-bottom: 1.6rem;
            width: 100%;
            & div {
              display: inline-block;
            }
          }
          .btn {
            width: 100%;
            margin-bottom: 1.6rem;
          }
          .search-box2 {
            margin: 0 0 1.6rem;
            width: 100%;
            .search-inner-box {
              .search_b {
                width: 100%;
              }
            }
          }
        }
      }

      &.popup-modify {
        .popup-content {
          .field-form {
            .field-box {
              .unit {
                margin: 0 0 1.6rem;
              }
            }
          }
        }
      }

      &.popup-alert-message,
      &.fullpopup {
        transform: unset;
        margin-top: 8rem;
        left: 0;
        width: 100%;
        .popup-content {
          padding: 0 1.6rem;
          height: calc(100vh - 35rem);
          .tablist-content {
            width: 100%;
            height: auto;
            padding: 1.6rem;
          }
          .tab-panel {
            padding: 0 !important;

            .search-box2 {
              margin-left: 0;
            }

            .alert-guide-txt {
              margin: 1.6rem 0 !important;
            }
          }
        }

        .table {
          width: 100%;
          margin: 0;
        }
      }
      .tablist-content {
        .tab-panel {
          .data-state {
            display: block;
            margin-bottom: 1.2rem;
            >:first-child {
              width: 100%;
              margin-bottom: 1.6rem;
              button {width: 100%;}
            }
            >:last-child {
              float: right;
            }
          }
        }
      }
      &.popup-add-template {
        .popup-content {
          .field {
            .field-form {
              textarea {
                min-height: 14.4rem;
              }
              input[type="text"] {
                margin: 0;
                min-height: 3.6rem;
              }
              .field-box > *.btn {
                width: 100%;
                margin: 0;
              }
              #ip-sound-file {
                margin-bottom: 1.6rem;
              }
            }
          }
        }
      }
      &.popup-select-file {
        .checkbox {
          height: 1.9rem;
          margin-bottom: 0;
          input[type="checkbox"] + label {
            width: 1.9rem;
            height: 1.9rem;
            padding: 0;
          }
        }
        .filed-type-table {
          display: none;
        }
        .file-header {
          padding: 0 1.2rem;
          display: flex;
          background-color: #f5f6fa;
          height: 4.4rem;
          align-items: center;
          font-size: 1.4rem;
          font-weight: 700;
          color: #697387;
          border-bottom: 2px solid #dcdcdc;
          line-height: 1.4;
          >:first-child {
            padding-left: 0.8rem;
            padding-right: 2rem;
            display: flex;
            height: 100%;
            align-items: center;
            border-right: 1px solid rgba(53, 64, 86, 0.1);
          }
          >:last-child {
            padding-left: 1.2rem;
          }
        }
        .file-item {
          .title-area {
            padding: 0 1.2rem;
            height: 5.4rem;
            display: flex;
            align-items: center;
            font-size: 1.4rem;
            border-bottom: 1px solid #d7d7d7;
            >:nth-child(1) {
              padding-left: 0.8rem;
              padding-right: 2rem;
              display: flex;
              height: 100%;
              align-items: center;
              border-right: 1px solid rgba(53, 64, 86, 0.1);
            }
            >:nth-child(2) {
              padding-left: 1.2rem;
              display: flex;
              flex-grow: 2;
              justify-content: space-between;
            }
          }
          .contents {
            > div {
              height: 5.4rem;
              display: flex;
              align-items: center;
              background-color: #E8E8F0;
              border-bottom: 1px solid #dcdcdc;
              padding: 0 1.2rem;
              font-size: 1.4rem;
              >:first-child {
                flex-basis: 50%;
                color: #697387;
                font-weight: 700;
              }
              >:last-child {
                padding-left: 0.8rem;
                flex-basis: 50%;
              }
            }
          }
        }
      }
      &.popup-file-box {
        .mobile-only.dropdown-type-a {
          margin-bottom: 3.2rem;
        }
        .file-header {
          padding: 0 1.2rem;
          background-color: #f5f6fa;
          height: 4.4rem;
          font-size: 1.4rem;
          font-weight: 700;
          color: #697387;
          border-bottom: 2px solid #dcdcdc;
          line-height: 1.4;
          >:first-child {
            padding-right: 2rem;
            display: flex;
            height: 100%;
            align-items: center;
          }
        }
        .file-item {
          .title-area {
            padding: 0 1.2rem;
            height: 5.4rem;
            display: flex;
            align-items: center;
            font-size: 1.4rem;
            border-bottom: 1px solid #d7d7d7;
            >:nth-child(1) {
              padding-left: 0.8rem;
              display: flex;
              flex-basis: 100%;
              height: 100%;
              align-items: center;
              justify-content: space-between;
            }
          }
          .contents {
            > div {
              height: 5.4rem;
              display: flex;
              align-items: center;
              background-color: #E8E8F0;
              border-bottom: 1px solid #dcdcdc;
              padding: 0 1.2rem;
              font-size: 1.4rem;
              >:first-child {
                flex-basis: 50%;
                color: #697387;
                font-weight: 700;
              }
              >:last-child {
                padding-left: 0.8rem;
                flex-basis: 50%;
              }
            }
          }
        }
        .popup-content {
          .field {
            .file-standard-info {
              padding-top: 3.2rem;
              padding-bottom: 2.4rem;
            }
          }
          .btn-data-download {
            padding: 0;
            width: 3.6rem;
            height: 3.6rem;
            border: 1px solid $color-border-05;
            border-radius: 100%;
            background: asset-image('ic_download.svg') no-repeat center / 2.4rem auto;
            background-color: #fff;
            font-size: 0;
          }
          .btn-data-delete {
            background-color: #fff;
          }
        }
      }
      &.popup-modify,
      &.popup-assigned-user,
      &.popup-email-recipients {
        .popup-content {
          .email-recipients-box {
            .field-type-email {
              .field-form {
                .field-box {
                  display: block;
                  input[type="text"] {
                    margin: 0; 
                  }
                }
                .btn {
                  margin: 1.2rem 0 0;
                  width: 100%;
                }
              }
              .email-list {
                margin: 1.6rem 0 0;
                li {
                  flex-wrap: wrap;
                  .role-name {
                    display: block;
                    width: 100%;
                    margin: .8rem 0 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .txt-group { 
    margin-bottom: 1.6rem;
  }

  .field-type-textarea {
    .btn {
      width: 100%;
    }
  }

  .field-exceptions-address {
    .btn {
      &.btn-Add {
        width: 100% !important;
        margin: 1rem 0;
      }
    }
  }

  .priority-list {
    li {
      .priority-con {
        span {
          display: block;
        }
      }
    }
  }

  .dropdown-type-a {
    &.mobile-only {
      margin-bottom: 1rem;
    }
  }

}

// 400%
@media screen and (max-width: 480px) {
  // html {font-size: 10px; }
}

// 150% 이상 확대 시 팝업 창이 꽉 차는 현상(높이 큰 사이즈 기준)
@media screen and (max-height:700px) {
  .popup-wrapper {
    .popup {
      &.large {
        top: 1rem;
        left: 1rem;
        right: 1rem;
        bottom: 1rem;
        max-width: calc(100% - 2rem) !important;
        max-height: calc(100% - 2rem) !important;
        transform: translate(0, 0);
        .popup-container {
          max-height: 100%;
        }
      }
    }
  }
}

// 250% 이상 확대 시 팝업 창이 꽉 차는 현상(높이 가장 작은 사이즈 기준)
@media screen and (max-height:400px) {
  .popup-wrapper {
    .popup {
      &:not(.large) {
        top: 1rem;
        left: 1rem;
        right: 1rem;
        bottom: 1rem;
        max-width: calc(100% - 2rem) !important;
        max-height: calc(100% - 2rem) !important;
        transform: translate(0, 0);
        .popup-container {
          max-height: 100%;
        }
      }
    }
  }
}