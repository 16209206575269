@charset 'UTF-8';

// 1. Configuration and helpers
@import
'abstracts/variables',
'abstracts/functions',
'abstracts/mixins';

.header-container {
  position: fixed;
  z-index: 100;
  width: 100%;
min-width: 14.4rem;
  height: 6rem;
  padding: 0 3rem 0 8rem;
  border-bottom: 1px solid $color-border-03;
  // background-color: $color-white;
  background: linear-gradient(90deg, #7596D9 0%, #8DBCE2 52.77%, #679CC7 92.03%);
  transition:padding-left .25s;

  &.no-padding {
    padding-left:2rem;
  }

  &.expand {
    padding-left:29.6rem;
  }
}

header {
  @include set-flex(row, space-between, center);
  height: 6rem;

  .left-side {
    @include set-flex(row, flex-start, center);
    height: 6rem;

    .logo {
      @include set-font(1.8rem, bold, $color-gray-01, normal);

      img {
        display: block;
        width: 24.4rem;
        // height: 2rem;
      }
    }
  }

  .right-side {
    @include set-flex(row, flex-end, center);
    @include set-flex-grow(1);
    height: 6rem;
  }



  .notice-list-container {
    display: none;
    position: fixed;
    top: 6.6rem;
    right: 2rem;
    width: 63.3rem;
    z-index: 20;
    border-radius: 1.5rem;
    border: 1px solid $color-border-03;
    background-color: $color-white;
    box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.25);

    &.show {
      display: block;
    }

    .notice-header {
      @include set-flex(row, space-between, center);
      padding: 1.5rem;
      border-bottom: 1px solid $color-border-03;

      .title {
        @include set-font(1.95rem, 400, $color-black, normal);
      }

      .btn-close {
        overflow: hidden;
        display: block;
        width: 22px;
        height: 22px;
        border-radius: 4rem;
        background: asset-image('x_btn_icon.svg') center no-repeat;
        text-indent: -9999px;
      }
    }

    .notice-content {
      padding: 2rem;
      .top-area {
        @include set-flex(row, space-between, center);
      }
      .btn-more {
        margin-left: auto;
        margin-bottom: 1rem;
      }

      // Set Table cell width //
      $tbody-height:20.5rem;
      $table-height:$tbody-height + 5.2rem;

      .table-wrapper {
        &.notice-board {
          table {
            height:$table-height;
            tbody {
              height:$tbody-height;
              tr {
                td {
                  padding: 0.5rem 0.7rem;
                  height: 4.1rem;
                }
              }
            }
            th, td {
              &:nth-child(1) {
                width:90px;
              }
              &:nth-child(2) {
                width:auto;
              }
  
              &:nth-child(3) {
                width:25%;
              }
            }
          }
        }
      }
    }

    .notice-footer {
      padding: 2rem;
      padding-top: 0;

      .btn-more {
        min-width: 8.4rem;
      }
    }
  }
}
.user-account-container {
  display: none;
  position: fixed;
  top: 8rem;
  right: 3rem;
  width: 42.6rem;
  z-index: 20;
  border-radius: 1.5rem;
  border: 1px solid $color-border-03;
  background-color: $color-white;
  box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.25);

  &.show {
    display: block;
  }

  .account-header {
    @include set-flex(row, space-between, center);
    padding: 1.5rem;
    border-bottom: 1px solid $color-border-03;

    .title {
      @include set-font(1.95rem, 400, $color-black, normal);
    }

    .btn-close {
      overflow: hidden;
      display: block;
      width: 30px;
      height: 30px;
      background: asset-image('btn_popup_close_nor.svg') center no-repeat;
      text-indent: -9999px;
    }

  }
  .account-content {
    padding: 2.5rem 2rem;
  }
  .text-box {
    @include set-font(1.35rem, 400, #333, normal);
    text-align: center;
    margin-bottom: 2.5rem;
    p {
      margin-bottom: 0;
    }
  }
  .button-container {
    @include set-flex(column, center, center);
  }
  .btn {
    &.btn-large {
      min-width: 28.7rem;
    }
    & + .btn {
      margin-top: 1rem;
    }
  }

}

.features {
  @include set-flex(row, center, center);

  &>li {
    position: relative;
    @include set-flex(row, center, center);
    @include set-font(1.3rem, 500, $color-gray-01, normal);
    height: 1.5rem;
    padding: 0 1.6rem;
    border-left: 1px solid rgba(0, 0, 0, 0.1);

    &:first-child {
      border-left: none;
    }

    & > a {
      position: relative;
      padding: 0 1.3rem;
      @include set-font(1.4rem, 500, $color-gray-01, 2.4rem);

      &:hover, &:focus-visible {
        text-decoration:underline;
      }
      &:active {
        text-decoration:none;
        &:after {
          content: '';
          position: absolute;
          top: -1px;
          left: 4px;
          right: 4px;
          bottom: -1px;
          border: 3px solid #a50034;
          border-radius: 20px;
        }
      }
    }

    .btn-icon {
      margin-left: 1.6rem;
      border-radius: 4px;

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.20);
      }

      &:active,
      &:focus {
        background-color: rgba(0, 0, 0, 0.40);
      }
    }

    > .btn {
      border: 0;
      // &:active {
      //   padding:0 1.6rem;
      // }
    }

    .btn {
      &.btn-secondary {
        background-color: rgba(0, 0, 0, 0.3);
        &:hover {
          background-color: rgba(0, 0, 0, 0.5);
        }
        &:active {
          background-color: rgba(0, 0, 0, 0.6);
        }
        &:focus-visible {
          box-shadow: none;
        }
      }
    }

    .btn-Business {
      padding: 0 1.6rem 0 1rem;
      em {
        display: inline-block;
        margin-right: 0.5rem;
        width: 1.6rem;
        height: 1.6rem;
        font-size: 1.2rem;
        line-height: 1.2;
        text-align: center;
        border-radius: 0.8rem;
        background-color: #354056;
        color: #fff;
      }
      // &:active {
      //   padding-left: 1rem;
      // }
    }

    .btn-Workspace {
      padding: 0 1.6rem 0 1rem;
      em {
        display: inline-block;
        margin-right: 0.5rem;
        width: 1.6rem;
        height: 1.6rem;
        font-size: 1.2rem;
        line-height: 1.2;
        text-align: center;
        border-radius: 0.8rem;
        background-color: #4077BF;
        color: #fff;
      }
    }

    .btn-support {
      margin-left: 1.6rem;
      padding: 0 3rem 0 1.6rem;
      // background: asset-image('header/ic_help.svg') no-repeat 1rem center / 1.6rem auto;
      background: asset-image('header/ic_support.svg') no-repeat 88% center / 1.6rem auto;

      // &:active {
      //   padding-left: 3rem;
      // }
    }

    // Expend Session Time 구버전//
    .enable-time {
      @include set-flex(row, flex-start, center);
      @include set-font(1.6rem, 700, $color-white, normal);
      margin-right: 0.8rem;
      padding-left: 2.6rem;
      height: 2.4rem;
      background: asset-image('header/ic_time_fill.svg') left center no-repeat;
      text-shadow: 0px 0px 3px rgba(0, 0, 0, 1);
    }

    // Notification //
    .btn-streaming {
      background: asset-image('header/ic_streaming.svg') no-repeat center / 2.8rem auto;
    }
    .btn-message {
      background: asset-image('header/ic_message_fill.svg') no-repeat center / 2.8rem auto;
    }
    .btn-group {
      background: asset-image('header/ic_group_fill.svg') no-repeat center / 2.8rem auto;
    }
    .btn-alarm {
      // position: relative;
      // border: none;
      // width: 3.2rem;
      // height: 3.2rem;
      // padding: .6rem;
      // margin: 0 1.1rem;
      // border-radius: 0;
      // border: none;
      background: asset-image('header/ic_notification_fill.svg') no-repeat center / 2.8rem auto;
      box-sizing: content-box;

      .alarm-bedge {
        position: absolute;
        display: block;
        top: 0rem;
        left: 55%;
        z-index:999;
        min-width: 2rem;
        height: 1.7rem;
        padding: 0 0.559rem;
        border-radius: 3rem;
        background-color: $color-primary;
        @include set-font(1rem, 500, $color-white, 1.7rem);
        text-align: center;
      }
    }
    .btn-profile {
      background: asset-image('header/ic_profile_fill.svg') no-repeat center / 2.8rem auto;
    }
    .btn-apps {
      background: asset-image('header/ic_apps_fill.svg') no-repeat center / 2.8rem auto;
    }

    // old Account //
    .btn-account {
      @include set-font(1.3rem, 400, $color-gray-01, normal);
      min-width: 1.34rem;
      height: 3.4rem;
      padding: .4rem 1.3rem;
      padding-left: 4rem;
      border-radius: 1.7rem;
      background: asset-image('icon_person_01.svg') no-repeat 10px / 2.4rem auto;
      text-align: left;

      &:after {
        content: '';
        display: inline-block;
        width: 11px;
        height: 6px;
        margin-left: 1.4rem;
        background: asset-image('header_btn_drop_down.svg') no-repeat center / 1.1rem auto;
        vertical-align: middle;
      }

      &:hover, &:focus-visible {
        text-decoration: underline;
      }
      &:active {
        text-decoration: none;
        background-color: #f4f4f4;
        border-radius: 1.7rem;
      }

      &.active {
        background-color: #f4f4f4;
        text-decoration: none;
        border-radius: 1.7rem;

        &:after {
          position:relative;
          top:-2px;
          background: asset-image('header_btn_drop_up.svg') no-repeat center / 1.1rem auto;
        }
      }

    }

    // new Account //
    .btn-user-account {
      margin-left: 1.5rem;
      @include set-font(1.3rem, 400, $color-gray-01, normal);
      width: 2.4rem;
      height: 2.4rem;
      background: asset-image('header_icon_profile_nor.svg') no-repeat center / 2.4rem;

      &:hover, &:focus-visible {
        text-decoration: underline;
        background: asset-image('header_icon_profile_ov.svg') no-repeat center / 2.4rem;
      }
      &.active {
        background: asset-image('header_icon_profile_pre.svg') no-repeat center / 2.4rem;
      }

    }

    .menu {
      display: none;
      position: absolute;
      top: 3rem;
      right: 0;
      width: auto;

      &.expand {
        display: block;
      }
      &.pannel {
        overflow: hidden;
        background-color: $color-white;
        border-radius: 0.8rem;
        box-shadow: 0px 1rem 3rem 0px rgba(0, 0, 0, 0.20);
        .menu-title {
          padding: 2.4rem;
          @include set-font(1.8rem, 700, #111, 1.3);
        }
        .menu-content {
          padding: 0 2.4rem;
        }
        .menu-btn-wrap {
          padding: 2.4rem;
          text-align: center;
          .btn {
            position: relative;
            display: inline-flex;
            padding: 1.4rem;
            min-width: 88%;
            height: 4.8rem;
            border: 1px solid $color-border-05;
            border-radius: 0.8rem;
            @include set-font(1.6rem, 500, $color-gray-01, 1.1);
            align-items: center;
            justify-content: center;
            &.btn-Signout:after {
              content: "";
              display: inline-block;
              margin-left: 0.4rem;
              width: 2rem;
              height: 2rem;
              background: asset-image('ic_sign out.svg') no-repeat center / 100% auto;
            }
          }
        }
        .btn-close {
          position: absolute;
          right: 2.4rem;
          top: 2.4rem;
          padding: 0;
          width: 2.4rem;
          height: 2.4rem;
          border: 0;
          background:asset-image('btn_popup_close_nor.svg') no-repeat center / 100%;
          font-size: 0;
          border-radius: 50%;

          &:hover {
            background-color: #DADDE2 !important;
          }

          &:active {
            background-color: #BEC2CB !important;
            box-shadow: none !important;
          }
        }
      }
      &.site-menu {
        right: 1.6rem;
        top: 4.5rem;
        width: 24rem;
        padding-right: 0.8rem;

        .menu-title {
          padding: 1.65rem 1.6rem;
        }
        .btn-close {
          right: 1.6rem;
          top: 1.8rem;
          width: 2rem;
          height: 2rem;
        }
        ul {
          border: 0;
          border-radius: 0;
          box-shadow: none;

          &.site-menuul {
            height: 26rem;
            overflow-y: auto;
            // 테스트
          }

          li {
            > .btn {
              padding: 1.2rem 1.6rem;
              border-radius: 0;
              @include set-font(1.4rem, 400, $color-gray-01, 1.4);
              &:before {
                border-radius: 0 !important;
              }
              &:hover {
                background-color: $color-side-bar !important;
              }
              em {
                display: inline-block;
                margin-right: 0.4rem;
                width: 2rem;
                height: 2rem;
                text-align: center;
                border-radius: 2rem;
                @include set-font(1.2rem, 400, $color-white, 2rem);
              }
              &.btn-Business {
                em {
                  background-color: $color-secondary;
                }
              }
              &.btn-Workspace {
                em {
                  background-color: #4077BF;
                }
              }
            }
            &.active {
              > .btn {
                background-color: $color-side-bar;
                &:after {
                  content: "";
                  position: absolute;
                  right: 1.6rem;
                  top: 50%;
                  width: 2rem;
                  height: 2rem;
                  background:asset-image('ic_check.svg') no-repeat center / 20px;
                  transform: translateY(-50%);
                }
              }
            }
            &.disabled {
              > .btn {
                // opacity: 0.4;
                opacity: 0.7;
                pointer-events: none;
              }
            }
          }
          &.site-menu-depth2 {
            li {
              > .btn {
                padding-left: 2.6rem;
              }
              &.active {
                > .btn {
                  padding-left: 1.6rem;
                }
              }
            }
          }
        }
        &.admin {
          ul {
            li {
              &.disabled {
                > .btn {
                  // 관리자용
                  opacity: 1;
                }
              }
            }
          }
        }
      }

      &.myAccount-menu {
        top: 6rem;
        width: 41rem;
        .myAccount-info {
          dt {
            padding-top: 2.4rem;
            @include set-font(1.6rem, 500, $color-dark-gray, 1.4);
            &:first-of-type {
              padding-top: 0;
            }
          }
          dd {
            padding-top: 0.4rem;
            @include set-font(1.6rem, 400, $color-dark-gray, 1.4);
            &.permission-wrap {
              padding-top: 1.2rem;
              .dropdown {
                .dropdown-menu {
                  position: relative;
                  top: 0.4rem;
                  .lists {
                    border: 0;
                    border-radius: 0;
                    .list {
                      display:block;
                      padding:1.2rem;
                      @include set-font(1.4rem, 400, $color-dark-gray, 1.4);
                      padding-right: 4rem;
                      span {
                        padding-right: 1rem;
                      }
                      a {
                        span {
                          padding-right: 1rem;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .btn-assigned {
          margin-top: 2.4rem;
        }
      }

      &.notice-menu {
        top: 6rem;
        width: 41rem;
        .menu-content{
          .notice-filter {
            @include set-flex(row, space-between, center);
            .btn {
              margin-left: 0.8rem;
              padding: 0 1.4rem;
              height: 2.8rem;
              &:active {
                border-width: 1px;
              }
              &:first-child {
                margin-left: 0;
              }
              &.active {
                background-color: #354056 !important;
                border-color: #354056 !important;
                color: #fff !important;
              }
            }
            .btn-Unread {
              &.new {
                &::after {
                  content: "";
                  margin: 0 0 .2rem .5rem;
                  display: inline-block;
                  width: 0.6rem;
                  height: 0.6rem;
                  border-radius: 100%;
                  background-color: #1D828F;
                }
              }
            }
            .btn-Allread {
              display: inline-flex;
              // padding-left: 1rem;
              align-items: center;
              justify-content: center;
              // &:before {
              //   content: "";
              //   display: inline-block;
              //   width: 1.2rem;
              //   height: 1.2rem;
              //   background:asset-image('ic_check.svg') no-repeat center / 100%;
              // }
            }
          }
          .notice-container {
            margin-top: 1.6rem;
            padding: 1.6rem 0 2.4rem;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            .notice-state {
              padding-bottom: 1.2rem;
              @include set-font(1.2rem, 400, $color-gray-01, 1.4);
              text-align: right;
            }
            .notice-wrap {
              margin: auto -1.6rem auto -1.2rem;
              padding: 0 1.2rem;
              overflow: auto;
              max-height: 58vh;
              .notice-item {
                position: relative;
                display: block;
                margin-top: 1.2rem;
                padding: 1.2rem;
                border: 1px solid $color-border-05;
                border-radius: 0.8rem;
                &:first-child {
                  margin-top: 0;
                }
                &:after {
                  content: "";
                  position: absolute;
                }
                &.state-unread {
                  &:after {
                    right: 1.2rem;
                    top: 1.2rem;
                    width: 0.8rem;
                    height: 0.8rem;
                    border-radius: 100%;
                    background-color: $color-primary;
                  }
                }
                &.state-read {
                  &:after {
                    right: 0.2rem;
                    top: 0.2rem;
                    width: 2.8rem;
                    height: 2.8rem;
                    background:asset-image('ic_check.svg') no-repeat center / 100%;
                  }
                }
                .notice-tit {
                  margin: 0;
                  padding-right: 1.6rem;
                  @include set-font(1.4rem, bold, $color-gray-01, 1.4);
                }
                .notice-detail {
                  overflow: hidden;
                  display: -webkit-box;
                  margin-top: 0.8rem;
                  max-height: 6rem;
                  text-overflow: ellipsis;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 3;
                  word-wrap: break-word;
                  @include set-font(1.4rem, 500, $color-gray-01, 1.4);
                }
                .notice-date {
                  display: block;
                  padding-top: 0.4rem;
                  @include set-font(1.2rem, normal, $color-gray-02, 1.4);
                }
                &:hover {
                  .notice-detail {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
      }

      &.cloudService-menu {
        top: 6rem;
        width: 41rem;
        .menu-content {
          .cloud-service-wrap {
            padding: 0.8rem 0;
            .cloud-service-row {
              margin-top: 2.4rem;
              .cloud-service-category {
                padding-bottom: 0.6rem;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                @include set-font(1.2rem, bold, $color-gray-02, 1.4);
              }
              .cloud-service-item {
                position: relative;
                margin-top: 1.6rem;
                padding: 0.9rem 3.2rem 0.9rem 5.2rem;
                @include set-font(1.6rem, normal, $color-gray-01, 1.4);
                .btn-move-detail {
                  position: absolute;
                  right: 0;
                  top: 50%;
                  padding: 0;
                  width: 2rem;
                  height: 2rem;
                  border: 0;
                  background:asset-image('ic_open.svg') no-repeat center / 100%;
                  font-size: 0;
                  transform: translateY(-50%);
                }
                
                &.business_cloud {
                  background:asset-image('cloud/ic_business_cloud.png') no-repeat left center / 4rem auto;
                }
                &.supersign_cloud {
                  background:asset-image('cloud/ic_supersign_cloud.png') no-repeat left center / 4rem auto;
                }
                &.supersign_ad {
                  background:asset-image('cloud/ic_supersign_ad.png') no-repeat left center / 4rem auto;
                }
                &.connected_care {
                  background:asset-image('cloud/ic_connected_care.png') no-repeat left center / 4rem auto;
                }
                &.connected_care_api {
                  background:asset-image('cloud/ic_connected_care_api.png') no-repeat left center / 4rem auto;
                }
                &.cloi_station {
                  background:asset-image('cloud/ic_cloi_station.png') no-repeat left center / 4rem auto;
                }
                &.pro_centric_cloud {
                  background:asset-image('cloud/ic_pro_centric_cloud.png') no-repeat left center / 4rem auto;
                }
                &.pro_centric_stay {
                  background:asset-image('cloud/ic_pro_centric_stay.png') no-repeat left center / 4rem auto;
                }
                &.eCentric {
                  background:asset-image('cloud/ic_e-centric.png') no-repeat left center / 4rem auto;
                }

                // &.eCentric {
                //   background:asset-image('cloud/ic_e-centric.png') no-repeat left center / 4rem auto;
                // }
                // &.proCentric {
                //   background:asset-image('cloud/ic_procentric.png') no-repeat left center / 4rem auto;
                // }
                // &.proCloud {
                //   background:asset-image('cloud/ic_procloud.png') no-repeat left center / 4rem auto;
                // }
                // &.superSign {
                //   background:asset-image('cloud/ic_supersign.png') no-repeat left center / 4rem auto;
                // }
                &.license {
                  background:asset-image('ic_info_fill.svg') no-repeat left center / 4rem auto;
                }
              }
              &:first-child {
                margin-top: 0;
              }
            }
          }
        }
        .menu-btn-wrap {
          .btn-Findout {
            &:after {
              content: "";
              display: inline-block;
              margin-left: 0.8rem;
              width: 2rem;
              height: 2rem;
              background:asset-image('ic_open.svg') no-repeat left center / 100%;
            }
          }
        }
      }

      ul {
        overflow: hidden;
        min-width:13rem;
        border-radius: 1.1rem;
        border: 1px solid #d0d1d2;
        background-color: $color-white;
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.2);

        &>li {
          // padding: 0.2rem;
          &:first-child {
            a {
              &:focus {
                &:before {
                  border-top-left-radius: 1.1rem;
                  border-top-right-radius: 1.1rem;
                }
              }
            }
          }
          &:last-child {
            a {
              &:focus {
                &:before {
                  border-bottom-left-radius: 1.1rem;
                  border-bottom-right-radius: 1.1rem;
                }
              }
            }
          }


          > a {
            position:relative;
            display: block;
            padding: 0.9rem 1.3rem;
            height: auto;
            border:0;
            white-space: nowrap;
            @include set-font(1.4rem, 400, $color-dark-gray, normal);
            &:focus {
      
              &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: 1px solid $color-primary;
              }
            }

            &:hover,
            &:focus {
              background-color: #efefef;
              text-decoration: underline;
            }

            &:active {
              background-color: #cccccc;
              text-decoration: none;
            }
          }
        }
      }
    }
    .features-pannel-wrap {
      position: relative;
      display: inline-block;
      margin-left: 1.6rem;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
.popup-wrapper {
  .popup {
    &.popup-my-info {
      max-width: 40rem;
      .popup-content {
        padding: 0;
      }
    }
  }
}
.my-info-container {

  &.show {
    display: block;
  }

  h1 {
    @include set-font(1.95rem, 400, $color-gray-01, 4.05rem);
  }

  h2 {
    @include set-font(1.65rem, 500, $color-gray-01, 4.05rem);
  }

  .my-info-header {
    padding: .5rem 2rem;
    border-bottom: 1px solid $color-border-03;
  }

  .my-info-middle-content {
    @include set-flex(row, flex-start, center);
    padding: 2.3rem 3rem 2.7rem;
    background-color: $color-content-bg;
    word-break: break-all;

    .my-picture {
      @include set-flex(row, center, center);
      width: 9.8rem;
      height: 9.8rem;

      border-radius: 1rem;
      background-color: $color-white;

      img {
        display: block;
      }
    }

    .my-id-and-role {
      @include set-flex-grow(1);
      // padding-left: 2rem; .my-picture 간격

      .my-id {
        @include set-font(1.95rem, 400, $color-gray-01);
        margin-bottom: .5rem;
      }

      .my-role {
        @include set-font(1.35rem, 400, $color-gray-02);
      }
      .info-label {
        &:after {
          content: ':';
          display: inline-block;
          padding: 0 5px;
        }
      }
    }
  }

  // .my-info-middle-content

  .my-info-bottom-content {
    padding: 0 3rem;
    margin-bottom: -2.6rem;
    .role-title {
      @include set-font(1.65rem, 500, $color-gray-01);
    }
    .role-inner {
      margin: 2.6rem 0;
    }
    ul {
      border-radius: 0;
      border: none;
      box-shadow: none;
      background-color: transparent;
      border-top: 1px solid $color-gray-02;

      li {
        @include set-font(1.35rem, 400, $color-gray-02);
        padding: 1.3rem 0;
        border-bottom: 1px solid $color-border-03;

        &:before {
          content: '- ';
        }
      }
    }
  }

  // .my-info-bottom-content

  .my-info-footer {
    @include set-flex(row, center, center);
    padding: 2rem;

    .btn {
      min-width: 14rem;
      &.btn-secondary {
        @include set-font(1.4rem, 400, $color-gray-01, normal);
        height:4.2rem;
        padding: 0 2.5rem;
        border-radius:2.1rem;
        border:1px solid $color-border-01;
        white-space:nowrap;

        &:hover {
          background-color: $color-content-bg-1;
          text-decoration: underline;
        }
        &:active {
          padding: 0 2.3rem;
          border:3px solid $color-primary;
          background-color: $color-border-02;
          text-decoration: none;
        }
      }
    }
  }

  // .my-info-footer
}

// Language //
.language-switcher {
  .icon-language {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    width: 2.4rem;
    height: 2.4rem;
    img {
      width: 100%;
    }
  }
 
  .btn-language {
    @include set-flex(row, flex-start, center); 
    @include set-font(1.4rem, 400, $color-gray-01, normal);
    // padding-left: 2.9rem;
    min-width: 15rem;
    padding: .5rem 1.1rem;
    border-radius: 1.7rem;

    &:hover {
      background-color: #f4f4f4;
      text-decoration: underline;
    }
  

    &:before {
      content:'';
      display: none; //다시 생길수도있으므로 숨김상태
      width:2.4rem;
      height:2.4rem;
      margin-right:.7rem;
      background:asset-image('header_icon_language.svg') no-repeat center / 24px;
    }

    &:after {
      content:'';
      display: block;
      width:1.3rem;
      height:0.8rem;
      margin-top:.2rem;
      margin-left:auto;
      background:asset-image('header_btn_drop_down.svg') no-repeat center / 13px 8px;
    }

    &.active {
      background-color: #f4f4f4;
      &:after {
        position: relative;
        top:-2px;
        background:asset-image('header_btn_drop_up.svg') no-repeat center / 13px 8px;
      }
    }
    
  }
  .language-menu {
      right: 0.5rem !important;
  }
}

// Side Navigation Bar popup
.side-nav-popup {
  width: 41rem;
  position: absolute;
  top: 8.8rem;
  left: 2.4rem;
  background-color: $color-white;
  border-radius: 0.8rem;
  box-shadow: 0px 1rem 3rem 0px rgba(0, 0, 0, 0.2);
  z-index: 9999;

  .nav-title {
    padding: 2.4rem;
    @include set-font(2rem, 700, #111);
  }

  .nav-content {
    padding: 0 2.4rem;

    .img-box {
      border-radius: 0.8rem;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .txt-box {
      @include set-font(1.2rem, 400, $color-gray-01);
      margin: 1.6rem 0;
    }

    .checkbox {
      label {
        font-size: 1.4rem;
      }
    }
  }

  .nav-btn-wrap {
    padding: 0 4.5rem 2.4rem;
    text-align: center;

    .btn {
      font-size: 1.6rem;
      font-weight: 500;
      width: 100%;
      height: 4.8rem;
    }
  }

}


@media screen and (max-width:991px) {
  .header-container {
    header {
      .notice-list-container {
        .notice-content {
          overflow:hidden;
          overflow-y: auto;
          border-radius: 0 0 1.5rem 1.5rem;
        }

        .notice-footer {
          padding: 0.5rem 2rem 1rem;
        }

        .table-wrapper {
          margin-bottom: 0;
        }
      }
    }

    // header
  }
}

//250%
@media screen and (max-width:877px) {
  .header-container {
    &.expand {
      padding-left:10.5rem;
    }
  }
  .my-info-container {
    .my-info-header {
      padding: 0.5rem 2rem;
    }

    .my-info-footer {
      padding: 0.5rem 2rem;
    }
  }
}

//300%
@media screen and (max-width: 767px) {
  .header-container {
    padding: 0 0.2rem;

    &.expand {
      padding-left:0.8rem;
    }

    header {
      .notice-list-container {
        left: 2rem;
        right: 2rem;
        width: auto;
      }

      .notice-content {
        .table-wrapper {
          &.notice-board {
            table {
              th, td {
                &:nth-child(1) {
                  width:50px;
                }
                &:nth-child(2) {
                  width:auto;
                }
                &:nth-child(3) {
                  width:35%;
                }
              }
            }
          }
        }
      }
    }

    // header
    .left-side {
      .btn-hamburger {
        display: block !important;
        width: 6rem;
        height: 6rem;
        padding: 0;
        border-radius: 0;
        border: none;
        // background: asset-image('menu_icon_menu_n.svg') center no-repeat;
        background: asset-image('header/ic_menu.svg') center no-repeat;

      }
    }
  }

  .my-info-container {
    
    .my-info-middle-content {
      display: block;
      padding:2rem;
      

      .my-info-contents {
        max-height: calc(100% - 10.2rem);
      }

      .my-picture {
        margin-bottom:1rem;
      }

      .my-id-and-role {
        padding-left:0;
      }
    }
  }

  .wrapper-account {
    .header-container {
      header {
        .features {
          > li {
            &:first-child, &:nth-child(2) {
              display:block;
            }
          }
        }
      }
    }
  }

  .language-switcher {
    .btn-language {
      min-width:auto;
      padding: 0;
        span {
          display: none;
        }
    }
  }
  .user-account-container {
    left: 2rem;
    right: 2rem;
    width: auto;
    .btn {
      &.btn-large {
        min-width: auto;
      }
    }
  }
  .features {
    & > li {
      .btn-account {
        padding-right: 0;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .header-container {
    .features {
      > li {
        border-left: none;
        & > *:not(.language-switcher) {
          display:none;
        }
      }
    }
  }

  .side-nav-popup {
    width: calc(100% - 4.8rem);
  }
}