@charset 'UTF-8';

// 1. Configuration and helpers
@import 'abstracts/variables',
'abstracts/functions',
'abstracts/mixins';

//================== Table ==================//a


//팝업 내 테이블 5개 이상일 경우 스크롤 생김 
$popup-tbody-height:20.5rem; //td height :41 * 5 
$popup-table-height:$popup-tbody-height+3.6rem; //thead height

.table-title {
  margin-bottom: 1.5rem;
}

.table-wrapper {
  position: relative;
  margin-bottom: 1.5rem;
  border-top: 1px solid $color-side-bar;

  // 스크롤 없음 //
  &.no-scroll {
    table {
      thead {
        width: 100%;
      }

      tbody {
        width: 100%;
        overflow: visible;
      }
    }
  }

  // 가로 스크롤 //
  &.horizontal-scroll {
    .table-box {
      overflow: hidden;
      overflow-x: auto;

      table {
        // min-width: 192rem;
        border-bottom: 1px solid $color-border-03;
      }
    }
  }

  &.double-thead {
    table {
      tbody {
        top: 6.7rem;
      }
    }
  }

  &.header-large {
    table {
      tbody {
        top: 6.7rem;
      }
    }

    tr {
      th {
        height: 6.7rem;

        .text-block {
          @include set-flex(column, center, center);
          height: 100%;

          .switch {
            margin-top: .5rem;
          }
        }
      }
    }

    .switch {
      vertical-align: middle;
    }
  }

  &.alt-table {
    border-top: none;

    .table-box {
      table {
        min-width: auto;
        width: auto;
        height: auto !important;
        table-layout: inherit;
        border-top: 1px solid $color-border-03;

        thead {
          tr {
            th {
              width: 8rem;
              padding: 0.7rem;
              white-space: nowrap;

            }
          }
        }

        tbody {
          height: auto !important;
          position: static;

          tr {

            th,
            td {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  .table-box {
    &.small {
      max-height: 24rem;
    }

    table {
      margin-bottom: 0;
    }
  }
}

.table-bottom {
  @include set-flex(row, space-between, center);
}

table:not(.has-not-header) {
  position: relative;
  height: 62.2rem;
  margin-bottom: 0;
  border-bottom: none;
  background-color: $color-white;


  .field {
    margin-bottom: 0;

    &.field-type-checkbox {
      height: 1.9rem;

      .field-form {
        .checkbox {
          height: 1.9rem;
          margin-bottom: 0;

          input[type="checkbox"]+label {
            width: 1.9rem;
            height: 1.9rem;
            padding: 0;
          }
        }
      }

      &.stay-label {
        .field-form {
          .checkbox {
            input[type="checkbox"]+label {
              width: auto;
              height: auto;
              // padding:0;
              padding-left: 3rem;
            }
          }
        }
      }
    }
  }

  .dropdown {
    .btn-dropdown[aria-selected=true] {
      &+.dropdown-menu {
        position: fixed;
        top: inherit;
        left: inherit;
        margin-top: 0.5rem;
      }
    }
  }

  thead {
    // width:100%;
    width: calc(100% - 0.4rem);
    display: block;
    border-bottom: 2px solid $color-side-bar;
    background-color: #F5F6FA;

    tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    td,
    th {
      @include set-font(1.4rem, 700, #4B5468, normal);
      padding: 0;
      border-right: 1px solid $color-side-bar;
      vertical-align: middle;
      // text-align: center;
      // text-transform: capitalize;

      &:last-child {
        border-right: none;
      }

      .btn-sort,
      .text-block {
        // display:block;
        @include set-flex(row, flex-start, center);
        overflow: hidden;
        width: 100%;
        height: 4.4rem;
        padding: .8rem 1.2rem;
        // border-bottom:2px solid $color-border-03;
        background-color: #F5F6FA;

      }

      .btn-sort {
        width: 100%;
        padding: .8rem 1rem;
        border-radius: 0;
        border: none;
        @include set-font(1.4rem, 700, #697387, normal);

        &:after {
          content: '';
          display: inline-block;
          flex-shrink: 0;
          width: 1rem;
          height: .6rem;
          margin-left: 0.8rem;
          background: asset-image('arrow_down_nor.svg') no-repeat center/ 100%;
          transition: transform .25s;
        }

        &.asc {
          &:after {
            transform: rotate(180deg);
          }
        }

        &:active {
          background-color: #d7d7d7;
        }
      }

      .field {
        height: 3.6rem;
        margin-bottom: 0;
        padding: 0;
        // border-bottom:2px solid $color-border-03;
      }

      .dropdown {
        .btn-dropdown {
          height: 3.4rem;
          padding: .8rem 1rem;
          white-space: nowrap;
          border: none;
          font-size: 1.3rem;
          line-height: 1.8rem;

          &:after {
            top: calc(50% - 0.2rem);
          }
        }

        .dropdown-menu {
          .lists {
            .list {
              a {
                text-align: left;
              }
            }
          }
        }
      }

      &:last-child {

        .btn-sort,
        .field,
        .text-block {
          border-right: none;
        }
      }
      
      &.align-center {
        text-align: center;
      }
    }

    // th
  }

  // thead

  tbody {
    display: block;
    overflow-y: auto;
    position: absolute;
    top: 4.6rem;
    left: 0;
    // width:calc(100% + 0.4rem);
    width: 100%;
    height: 57.6rem;
    border-bottom: 1px solid $color-side-bar;

    tr {
      display: table;
      width: 100%;
      border-bottom: 1px solid $color-side-bar;
      table-layout: fixed;

      &:last-child {
        border-bottom: none;
      }

      // &:hover {
      //   background-color: #efefef;
      // }

      &:active {
        text-decoration: none;
        background-color: #e4e4e4;
      }



      th {
        @include set-font(1.4rem, 700, #697387, normal);
        padding: 0.7rem;
        border-right: 1px solid $color-side-bar;
        background-color: #F5F6FA;
        text-align: center;
        vertical-align: middle;
        word-break: break-all;

        &.align-left {
          text-align: left;
          padding-left: 1.9rem;
        }
      }

      td {
        position: relative;
        @include set-font(1.4rem, 400, $color-gray-01, normal);
        padding: 0.7rem 1.2rem;
        height: 5.4rem;
        border-right: 1px solid $color-side-bar;
        // text-align: center;
        vertical-align: middle;
        word-break: break-all;
        cursor: pointer;

        .btn {
          width: 100%;
        }

        &.point {
          color: #D92D20;
        }
        &.point-blue {
          color: #1D76FB;
        }

        &.align-left {
          text-align: left;
        }

        &.align-center {
          text-align: center;
        }

        &:last-child {
          border-right: none;
        }

        &.alert {
          @include set-font(1.6rem, 500, $color-gray-01, normal);
          background-color: #ddecff;
          text-align: center;
        }

        .search-result {
          color: #F04438;
        }


        // 개발소스에서 img가 block속성 들어있어서 가운데정렬 시킴
        img {
          margin: 0 auto;
        }
      }
    }

    .btn {

      &.btn-sort-up,
      &.btn-sort-down,
      &.btn-detail,
      &.btn-delete,
      &.btn-license,
      &.btn-decline,
      &.btn-accept,
      &.btn-edit,
      &.btn-download,
      &.btn-stop,
      &.btn-result {
        display: inline-block;
        overflow: hidden;
        width: 4rem;
        min-width: auto !important;
        height: 4rem;
        padding: 0;
        border-radius: 50%;
        border: 1px solid $color-border-01;
        text-indent: -9999px;
      }

      &.disabled {
        opacity: .4;
      }

      &.btn-sort-up {
        background: asset-image('ic_arrow_up.svg') center no-repeat;
      }
      &.btn-sort-down {
        background: asset-image('ic_arrow_down.svg') center no-repeat;
      }

      &.btn-detail {
        background: asset-image('btn_icon_details_nor.svg') center no-repeat;
      }

      &.btn-delete {
        background: asset-image('btn_icon_delete_nor.svg') center no-repeat;
      }

      &.btn-edit {
        background: asset-image('btn_icon_edit_nor.svg') center no-repeat;
      }

      &.btn-decline {
        background: asset-image('btn_icon_decline_nor.svg') center no-repeat;
      }

      &.btn-accept {
        background: asset-image('btn_icon_accept_nor.svg') center no-repeat;
      }

      &.btn-license {
        background: asset-image('btn_icon_license_nor.svg') center no-repeat;
      }
      &.btn-download {
        background: asset-image('ic_download.svg') center no-repeat;
      }
      &.btn-stop {
        background: asset-image('ic_pause.svg') center no-repeat;
      }
      &.btn-result {
        background: asset-image('ic_result.svg') center no-repeat;
      }

      &:focus,
      &:hover {
        background-color: #f5f5f5;
        // &.btn-detail {
        //   background: #eee asset-image('btn_icon_details_ov.svg') center no-repeat;
        // }

        // &.btn-delete {
        //   background: #eee asset-image('btn_icon_delete_ov.svg') center no-repeat;
        // }

        // &.btn-edit {
        //   background: #eee asset-image('btn_icon_edit_ov.svg') center no-repeat;
        // }

        // &.btn-decline {
        //   background: #eee asset-image('btn_icon_decline_ov.svg') center no-repeat;
        // }

        // &.btn-accept {
        //   background: #eee asset-image('btn_icon_accept_ov.svg') center no-repeat;
        // }

        // &.btn-license {
        //   background: #eee asset-image('btn_icon_license_ov.svg') center no-repeat;
        // }
      }

      // &:active {
        // border: 3px solid $color-primary;

        // &.btn-detail {
        //   background: $color-border-02 asset-image('btn_icon_details_pre.svg') center no-repeat;
        // }

        // &.btn-delete {
        //   background: $color-border-02 asset-image('btn_icon_delete_pre.svg') center no-repeat;
        // }

        // &.btn-edit {
        //   background: $color-border-02 asset-image('btn_icon_edit_pre.svg') center no-repeat;
        // }

        // &.btn-decline {
        //   background: $color-border-02 asset-image('btn_icon_decline_pre.svg') center no-repeat;
        // }

        // &.btn-accept {
        //   background: $color-border-02 asset-image('btn_icon_accept_pre.svg') center no-repeat;
        // }

        // &.btn-license {
        //   background: $color-border-02 asset-image('btn_icon_license_pre.svg') center no-repeat;
        // }
      // }

      // square style
      &.btn-square {
        height: auto;
        font-size: 1.3rem;
        border-radius: 5px;
        border-color: $color-border-02;
        color: $color-gray-03;
        background-color: $color-white;
      }
    }
  }

  .text-small {
    @include set-font(1.3rem, normal, $color-gray-03, normal);
  }
}

//================== Table has-not-header ==================//

table {

  &.has-not-header {
    border-top: 1px solid $color-border-01;
    border-bottom: 1px solid $color-border-01;

    thead {
      th {
        color: $color-gray-02;
      }
    }

    th,
    td {
      padding: 0 2rem;
      height: 5.2rem;
      vertical-align: middle;
      text-align: left;
    }

    th {
      background-color: #f8f8f9;
      @include set-font(1.4rem, 500, $color-gray-01, normal);
      &.child {
        background-color:$color-content-bg;
      }
    }

    td {
      @include set-font(1.4rem, 400, $color-gray-01, normal);

      button {
        margin-left: 1rem;
      }
    }
  }

}
table {
  .error,
  .warning {
    font-weight: 700 !important;
    color: $color-gray-01 !important;
  }
  .error {
    // <!-- 웹 접근성 수정 1st -->
    .icon-error {
      display: inline-block;
      position:relative;
      top:-0.2rem;
      margin-left: .5rem;
      width: 25px;
      height: 25px;
      background: asset-image('dashboard_icon_error.svg') no-repeat center / 24px;
      vertical-align: middle;
    }
  }
  
  .warning {
    // <!-- 웹 접근성 수정 1st -->
    // @include set-font(1.4rem, 700, $color-gray-01, normal);
  
    .icon-warning {
      display: inline-block;
      position:relative;
      top:-0.2rem;
      margin-left: .5rem;
      width: 28px;
      height: 25px;
      background: asset-image('dashboard_icon_warning.svg') no-repeat center / 24px;
      vertical-align: middle;
    }
  }
}

// ================ Convert Table to List ================ //

.convert-table-to-list {
  display: none;
  margin-bottom: 2rem;

  .table-header {
    display: table;
    width: 100%;
    border-top: 1px solid $color-side-bar;
    border-bottom: 2px solid $color-side-bar;
    background-color: $color-gray-04;

    .row {
      display: table-row;
    }

    .thead {
      @include set-font(1.4rem, 700, #697387);
      display: table-cell;
      padding: 1rem;
      border-right: 1px solid $color-side-bar;
      text-align: center;
      vertical-align: middle;

      &:first-child {
        width: 35%;
      }

      &:last-child {
        width: 65%;
        // @include set-flex-grow(1);
        border-right: none;
      }
    }
  }

  .table-body {
    .alert {
      @include set-font(1.6rem, 500, $color-gray-01, normal);
      padding: 1.2rem .8rem;
      background-color: #ddecff;
      text-align: center;
      border-bottom: 1px solid $color-border-03;
    }
    .table-row {
      border-bottom: 1px solid $color-side-bar;

      .summary-data {
        // @include set-flex(row, flex-start, flex-start);
        display: table;
        width: 100%;

        .row {
          display: table-row;
        }

        .table-cell {
          // @include set-flex(row, flex-start, center);
          @include set-font(1.4rem, 400, $color-gray-01);
          display: table-cell;
          padding: 1rem;
          border-right: 1px solid $color-side-bar;
          vertical-align: middle;

          .btn-expand {
            white-space: initial;
            height: auto;
          }

          &.center {
            text-align: center;
          }

          &:first-child {
            position: relative;
            width: 35%;
            min-width:85px;
          }

          &:last-child {
            width: 65%;
            padding: 0;
            // @include set-flex-grow(1);
            border-right: none;

            .btn {
              width: 100%;
              padding: 1rem 3.5rem 1rem 1rem;
              border-radius: 0;
              border: none;
              background: transparent asset-image('navi_btn_drop_down_3.svg') calc(100% - 1.2rem) center no-repeat;
              text-align: left;
              word-break: break-word;
            }
          }
        }

        // .table-cell
      }

      .all-data-box {
        overflow: hidden;
        height: 0;
      }

      .all-data {
        border-bottom: 1px solid $color-border-03;

        &>li {
          @include set-flex(row, flex-start, flex-start);
          @include set-font(1.4rem, 400, $color-gray-01);
          padding: 1.4rem 1rem;
          border-bottom: 1px solid rgba(53, 64, 86, 0.15);
          background-color: $color-side-bar;
          min-height: 46px;

          &.block {
            display: block;
          }

          &.center {
            @include set-flex(row, center, center);
          }

          &.middle {
            @include set-flex(row, flex-start, center);
          }

          &.space-between {
            @include set-flex(row, space-between, flex-start);
          }

          &:last-child {
            border-bottom: none;
          }

          .field-label {
            @include set-font(1.4rem, 400, $color-gray-01);
            display: inline-block;
            word-break: keep-all;
            min-width:88px;
            max-width:88px;
            padding-right:0.6rem;
            font-weight: 700;
            color: #697387;
          }

          .field-content {
            @include set-flex-grow(1);
            display: inline-block;
            word-break: break-word;
            padding-left:0.6rem;

            &.point {
              color: #D92D20;
            }
            &.point-blue {
              color: #1D76FB;
            }

            .field:last-of-type {
              margin-bottom: 0;
            }

            &.has-inner {
              display: block;
              margin-left: 6rem;

              .field-inner {
                display: block;
              }
            }
          }

          .field {
            margin-bottom: 0;

            &.field-type-input {
              width: 100%;
            }

            &.field-type-dropdown {
              width: 100%;
            }
          }
        }
      }

      &.expand {
        .summary-data {
          .table-cell {
            .btn-expand {
              background: transparent asset-image('navi_btn_up.svg') calc(100% - 1.2rem) center no-repeat;
            }
          }
        }

        .all-data-box {
          overflow: visible;
          height: auto;
        }
      }
    }

    // .table-row
  }

  // .table-body

  .btn {
    &.btn-sort-up,
    &.btn-sort-down,
    &.btn-detail,
    &.btn-delete,
    &.btn-license,
    &.btn-decline,
    &.btn-accept,
    &.btn-edit,
    &.btn-download,
    &.btn-stop,
    &.btn-result {
      display: inline-block;
      overflow: hidden;
      width: 4rem;
      min-width: auto !important;
      height: 4rem;
      padding: 0;
      border-radius: 50%;
      border: 1px solid $color-border-01;
      text-indent: -9999px;
    }

    &.disabled {
      opacity: .4;
    }

    &.btn-sort-up {
      background: $color-white asset-image('ic_arrow_up.svg') center no-repeat;
    }

    &.btn-sort-down {
      background: $color-white asset-image('ic_arrow_down.svg') center no-repeat;
    }

    &.btn-detail {
      background: $color-white asset-image('btn_icon_details_nor.svg') center no-repeat;
    }

    &.btn-delete {
      background: $color-white asset-image('btn_icon_delete_nor.svg') center no-repeat;
    }

    &.btn-edit {
      background: $color-white asset-image('btn_icon_edit_nor.svg') center no-repeat;
    }

    &.btn-decline {
      background: $color-white asset-image('btn_icon_decline_nor.svg') center no-repeat;
    }

    &.btn-accept {
      background: $color-white asset-image('btn_icon_accept_nor.svg') center no-repeat;
    }

    &.btn-license {
      background: $color-white asset-image('btn_icon_license_nor.svg') center no-repeat;
    }
    &.btn-download {
      background: $color-white asset-image('ic_download.svg') center no-repeat;
    }
    &.btn-stop {
      background: $color-white asset-image('ic_pause.svg') center no-repeat;
    }
    &.btn-result {
      background: $color-white asset-image('ic_result.svg') center no-repeat;
    }

    // &:focus,
    // &:hover {
    //   &.btn-detail {
    //     background: #eee asset-image('btn_icon_details_ov.svg') center no-repeat;
    //   }

    //   &.btn-delete {
    //     background: #eee asset-image('btn_icon_delete_ov.svg') center no-repeat;
    //   }

    //   &.btn-edit {
    //     background: #eee asset-image('btn_icon_edit_ov.svg') center no-repeat;
    //   }

    //   &.btn-decline {
    //     background: #eee asset-image('btn_icon_decline_ov.svg') center no-repeat;
    //   }

    //   &.btn-accept {
    //     background: #eee asset-image('btn_icon_accept_ov.svg') center no-repeat;
    //   }

    //   &.btn-license {
    //     background: #eee asset-image('btn_icon_license_ov.svg') center no-repeat;
    //   }
    // }

    &:active {
      border: 3px solid $color-primary;

      &.btn-sort-up {
        background: $color-border-02 asset-image('ic_arrow_up.svg') center no-repeat;
      }

      &.btn-sort-down {
        background: $color-border-02 asset-image('ic_arrow_down.svg') center no-repeat;
      }

      &.btn-detail {
        background: $color-border-02 asset-image('btn_icon_details_pre.svg') center no-repeat;
      }

      &.btn-delete {
        background: $color-border-02 asset-image('btn_icon_delete_pre.svg') center no-repeat;
      }

      &.btn-edit {
        background: $color-border-02 asset-image('btn_icon_edit_pre.svg') center no-repeat;
      }

      &.btn-decline {
        background: $color-border-02 asset-image('btn_icon_decline_pre.svg') center no-repeat;
      }

      &.btn-accept {
        background: $color-border-02 asset-image('btn_icon_accept_pre.svg') center no-repeat;
      }

      &.btn-license {
        background: $color-border-02 asset-image('btn_icon_license_pre.svg') center no-repeat;
      }

      &.btn-download {
        background: asset-image('ic_download.svg') center no-repeat;
      }
      &.btn-stop {
        background: asset-image('ic_pause.svg') center no-repeat;
      }

      &.btn-result {
        background: asset-image('ic_result.svg') center no-repeat;
      }
    }

    // square style
    &.btn-square {
      height: 4rem;
      font-size: 1.3rem;
      border-radius: 5px;
      border-color: $color-border-02;
      color: $color-gray-03;
      background-color: $color-white;
    }
  }

  .main-icon {
    position: absolute;
    left: 20%;
    top: 50%;
    transform: translateY(-50%);
  }
}
.convert-field-wrap {
  @include set-flex(column, flex-end, center);
  .right-side {
    .field {
      &.field-flex {
        .field-label {
          min-width: auto;
        }
      }
    }
  }
}

// ================ Popup table ================ //

.popup {
  .table-wrapper {
    table {
      height: $popup-table-height;

      // thead {
      //   th {
      //     border: none;
      //   }
      // } 팝업 안에 테이블에도 오른쪽 보더 필요해서 주석처리함

      tbody {
        height: $popup-tbody-height;

        tr {
          td {
            padding: 0.5rem 0.7rem;
            height: 5.4rem;
            // border-right: 0; 팝업 안에 테이블에도 오른쪽 보더 필요해서 주석처리함
          }

          .center{
            text-align: center;
          }
        }
      }
      &:not(.has-not-header) {
        tbody {
          td {
            padding: 0.8rem 1.2rem;
          }
        }
      }
    }
  }
}

// ================ No data table =============== //

.nodata-table {
  &.table-wrapper {
    table {
      height: 15.7rem; //thead + tbody

      tbody {
        width: 100%;
        height: auto;
        overflow-y: hidden;

        tr {
          height: auto;

          td {
            height: 12.1rem;
            cursor: initial;
          }
        }
      }
    }
  }

  .nodata-text {
    @include set-font(1.4rem, normal, $color-gray-01, normal);
    padding:4rem;
    text-align:center;
  }

  &.grid-elements {
    @include set-flex(row, center, center);
    .nodata-text {
      width: 100%;
    }
  }
}

// ================ No data table in Popup =============== //

.popup-wrapper {
  .popup {

    // nodata
    .nodata-table {
      &.table-wrapper {
        table {
          tbody {
            height: auto;
          }
        }
      }
    }
  }
}

// ================ Table Example =============== //

$tbody-height:13.6rem; //4개리스트
$table-height:$tbody-height+6.6rem;

.table-wrapper {
  &.double-thead {
    table {
      height: $table-height;

      tbody {
        height: $tbody-height;
      }

      thead {
        display: table;
        table-layout: fixed;

        tr {
          display: table-row;

          &:nth-child(1) {
            th {
              &:nth-child(1) {
                width: 9rem;
              }

              &:nth-child(2) {
                width: auto;
              }

              &:nth-child(3) {
                width: 30%;
                height: 3.2rem;
                border-bottom: 1px solid $color-border-03;
              }

              &:nth-child(4) {
                width: 15%;
              }

              &:nth-child(5) {
                width: 15%;
              }

              &:nth-child(6) {
                width: 15%;
              }
            }
          }

          &:last-child {
            th {
              border-right: 1px solid $color-border-03;
            }
          }

          th {
            border-bottom: 2px solid $color-border-03 !important;

            .btn-sort {
              height: 3.2rem;
              border: 0;
            }
          }
        }
      }

      td {
        &:nth-child(1) {
          width: 9rem;
        }

        &:nth-child(2) {
          width: auto;
        }

        &:nth-child(3) {
          width: 15%;
        }

        &:nth-child(4) {
          width: 15%;
        }

        &:nth-child(5) {
          width: 15%;
        }

        &:nth-child(6) {
          width: 15%;
        }
      }

      tbody {
        top: 6.7rem;
      }

      .btn-sort,
      .text-block {
        height: 3.2rem;
      }
    }
  }
}


table {
  .main-icon {
    position: absolute;
    left: 4%;
    top: 50%;
    transform: translateY(-50%);
  }
}


@media screen and (max-width:1280px) {
  table tbody tr td .btn {
    &.btn-square {
      padding: 0 0.5rem;
      white-space: normal;
      word-break: keep-all;
    }
  }

}

@media screen and (max-width:991px) {
  .convert-table-to-list {
    display: block;
    background-color: $color-white;
  }

  .table {
    &.show-always {
      .table-wrapper {
        display: block;
      }
    }
  }

  .table-wrapper,
  .table>table:not(.has-not-header) {
    display: none;
  }

  table.has-not-header {
    display: block;

    tbody {

      tr {

        th {
        }

        td {
          border-right: none;
        }
      }
    }
  }

}

@media screen and (max-width:640px) {
  table {
    thead {
      th {
        // position:relative;
        padding: 0;

        .btn-sort,
        .field,
        .text-block {
          border-right: none;
        }
      }
    }

    tbody {
      tr {
        td {
          border-right: none;
        }
      }
    }
  }
}