@charset 'UTF-8';

// 1. Configuration and helpers
@import
'abstracts/variables',
'abstracts/functions',
'abstracts/mixins';

.pagination {
  @include set-flex(row, center, center);
  flex-wrap: wrap;

  .pager {
    @include set-flex(row, center, center);
    flex-wrap: wrap;
  }

  .page {
    @include set-flex(row, center, center);
    min-width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    margin: 1rem 0.85rem;
    @include set-font(1.8rem, 400, $color-gray-01, normal);

    &.current,
    &:hover:not(.disabled) {
      color: $color-white;
      background-color: $color-primary;
    }

    &:active:not(.disabled) {
      color: $color-white;
      border: 3px solid $color-primary;
      background-color: #6b0022;

      &:before {
        top: -3px;
        left: -3px;
      }
    }
    &.disabled {
      opacity: 0.3 !important;
      cursor: default;
    }
  }

  >.page {
    position: relative;
    background-color: $color-gray-01;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: 2.8rem;
      height: 2.8rem;
    }

    &.go-before {
      margin-left: 0;

      &:before {
        background: asset-image('pagination_icon_left_2.svg') no-repeat center / 1.6rem 1.6rem;
      }
    }

    &.go-prev {
      &:before {
        background: asset-image('pagination_icon_left_1.svg') no-repeat center / 1.6rem 1.6rem;
      }
    }

    &.go-next {
      &:before {
        background: asset-image('pagination_icon_right_1.svg') no-repeat center / 1.6rem 1.6rem;
      }
    }

    &.go-after {
      margin-right: 0;

      &:before {
        background: asset-image('pagination_icon_right_2.svg') no-repeat center / 1.6rem 1.6rem;
      }
    }
  }

  &.small {
    max-width: 43rem;
    margin: 0 auto;
    margin-top:2rem;

    .page {
      width: 2.3rem;
      height: 2.3rem;
      margin: 0 .4rem;
      @include set-font(1.35rem, 400, $color-gray-01, normal);

      &.current {
        color: #fff;
      }

      &:hover:not(.disabled) {
        color: $color-white;
        background-color: $color-primary;
      }

      &:active:not(.disabled) {
        color: $color-white;
        border: 3px solid $color-primary;
        background-color: #6b0022;

        &:before {
          top: -3px;
          left: -3px;
        }
      }
    }

    >.page {
      position: relative;

      &:before {
        width: 2.3rem;
        height: 2.3rem;
      }

      &.go-before {
        margin-left: 0;

        &:before {
          background: asset-image('pagination_icon_left_2.svg') no-repeat center / 1.2rem 1.2rem;
        }
      }

      &.go-prev {
        &:before {
          background: asset-image('pagination_icon_left_1.svg') no-repeat center / 1.2rem 1.2rem;
        }
      }

      &.go-next {
        &:before {
          background: asset-image('pagination_icon_right_1.svg') no-repeat center / 1.2rem 1.2rem;
        }
      }

      &.go-after {
        margin-right: 0;

        &:before {
          background: asset-image('pagination_icon_right_2.svg') no-repeat center / 1.2rem 1.2rem;
        }
      }
    }

    // > .page
  }
}

//=========================== Mini Pagination ===========================//

.mini-pagination {
  display: none;
  width:100%;
  margin-top:2rem;

  .pager-box {
    @include set-flex(row, center, center);
  }

  .pager {
    @include set-font(1.4rem, 400, $color-gray-03);

    .separator {
      margin: 0 .1rem;
    }
  }

  .page {
    margin: 0 .3rem;
    font-size:1.7rem;
  }

  .btn-more {
    height:2.8rem;
    margin-left:1rem;
    line-height:2.6rem;
  }
}

@media screen and (max-width: 991px) {
  .mini-pagination {
    @include set-flex(row, center, center);
  }

  .long-pagination {
    display: none;
  }
}