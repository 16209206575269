@charset 'UTF-8';

// 1. Configuration and helpers
@import
'abstracts/variables',
'abstracts/functions',
'abstracts/mixins';

@import
'header',
'sidebar',
'pagination',
'table',
// 'site_group_list',
'popup';

html,
body,
#root {
  height: 100%;
}

.wrapper {
  width: 100%;
  // 고해상도 높이조절
  height: 100%;
}

.content-container {
  width: 100%;
  // 고해상도 높이조절
  display: table;
  height: 100%;
  margin: 0 auto;
  padding-top: 6rem;
  // padding-left: 8rem;
  transition:padding .25s;

  &.wide-padding {
    padding-left:35.2rem;

    &.expand {
      padding-left:68.2rem;
    }
  }

  &.no-padding {
    padding-left:0;
  }

  &.expand {
    // padding-left:33rem;
    position: relative;
    padding-left: 36.8rem;
  }

  // 고해상도 높이조절
  main {
    &.content-box {
      height: calc(100% - 6rem);
    }
  }
}

.content-container {
  .content-box {
    // &.show-lnb {
    //   padding-left: 33rem;
    // }
    .content-top {
      @include set-flex(row, space-between, center);
      flex-wrap:wrap;
      padding: 2.3rem 3.2rem 1.2rem 3.2rem;

      .top-left {
        @include set-flex(row, flex-start, center);
        margin-bottom:0.8rem;

        .btn-back {
          width: 3.5rem;
          height: 3.5rem;
          background: asset-image('ic_back.svg') no-repeat center / 2.4rem;
          border-radius: .4rem;
          margin-right: 1rem;
  
          &:hover {
            background-color: rgba(#000, 0.4);
          }
          &:focus {
            outline: 2px solid $color-black;
          }
        }

        h1 {
          @include set-flex(row, flex-start, center);
          margin-right: 1rem;
          font-size: 2.4rem;
          font-weight: 700;
          line-height: normal;
        }
      }

      .last-update {
        @include set-flex(row, flex-end, center);
        @include set-font(1.4rem, 500, $color-gray, normal);
        flex-wrap: wrap;
        margin-bottom:0.8rem;
        position: relative;

        .field-label {
          margin-right: 0.5rem;
          font-weight: 700;
          color: $color-dark-gray;
          white-space: nowrap;
        }

        .field-content {
          white-space: nowrap;
        }

        .btn-update {
          width: auto;
          height: auto;
          margin-left: .5rem;
          box-sizing: content-box;
          border-radius: .4rem;
          border: 1px solid #DADDE2;
          background-color: #fff;

          &:before {
            content: '';
            display: block;
            width: 2.6rem;
            height: 2.6rem;
            background: asset-image('ic_refresh.svg') no-repeat center / 1.8rem;
          }
          span {
            position: absolute;
            left: -9999px;
            text-indent: -9999px;
            font-size: 0;
          }
          &:hover {
            background-color: #f5f5f5;
          }
        }

        .tooltip-container {
          display: inline-block;
          margin-left: 2.5rem;

          .tooltip-content {
            min-width: 16rem;
            top: 3.4rem;
          }

          .btn-installation-code {
            &:hover {
              .tooltip-content {
                visibility: visible;
                opacity: 1;
              }
            }
          }
        }

        .btn-installation-code {
          height: 2.8rem;
          font-size: 1.2rem;
          border-radius: 0.4rem;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: -1.3rem;
            top: 50%;
            transform: translateY(-50%);
            width: 0.1rem;
            height: 1.8rem;
            background-color: rgba(0, 0, 0, 0.1);
          }
        }

        .installation-code {
          overflow: hidden;
          width: 24.4rem;
          height: 12.8rem;
          background-color: #fff;
          border-radius: 0.9rem;
          border: 1px solid #D0D1D2;
          box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.08);
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1;
          text-align: center;
          padding: 1.2rem 0;

          .code-time {
            @include set-font(1.6rem, 500, $color-gray-01, normal);
          }
          .code-num {
            @include set-font(2.4rem, 700, $color-gray-01, normal);
            margin: 0.5rem 0 0.7rem;

            .btn-refresh {
              width: 2.8rem;
              height: 2.8rem;
              padding: 0;
              border-radius: 50%;
              border: 1px solid #DADDE2;
              background: url("../images/ic_refresh.svg") no-repeat center/1.8rem;
              vertical-align: top;
              margin: 0.5rem 0 0 0.8rem;
            }
          }
          
          .btn-box {
            .btn {
              border-radius: 1.4rem;
              border: 1px solid #D2D0D1;
              padding: 0 1.2rem;
              height: 2.7rem;
              @include set-font(1.2rem, 500, $color-gray-01, normal);
            }
          }

          .btn-close {
            position: absolute;
            right: 1rem;
            top: 1rem;
            padding: 0;
            border: 0;
            background-size: 1.6rem;
          }
        }

      }
    }

    .content-middle {
      padding: 0 3.2rem 0 3.2rem;
      // 고해상도 높이조절
      height: calc(100% - 8rem);
      &.wide-padding {
        position: relative;
        padding-left: 36.8rem;
      }

      .content-middle-box {
        width: 100%;
        // 고해상도 높이조절
        height: 100%;
        min-height: 74rem;
        padding: 2.4rem 2.4rem 4rem 2.4rem;
        border-radius: 1rem;
        border: 1px solid $color-border-03;
        background-color: $color-white;
        overflow: hidden;

        .content-middle-top {
          @include set-flex(row, flex-start, center);
          flex-wrap: wrap;
          margin-bottom:2.4rem;
          .btn-open-child {
            display: none;
            min-width: 2.4rem;
            height: 2.4rem;
            margin-right: .8rem;
            padding:0;
            border-radius: .7rem;
            border: 1px solid $color-border-05;
            background: asset-image('ic_reflow-navi.svg') center no-repeat;
          }
          h2 {
            @include set-font(1.8rem, 700, #333333, 130%);
            margin-right: 1rem;
          }
          .title-info {
            @include set-font(1.9rem, 400, #757575, 130%);
            .font-blue {
              @include set-font(1.9rem, 700, #5689CD, 130%);
            }
          }
        }

        .detail-top,
        .detail-bottom {
          position: relative;
          padding-left: 6.5rem;
        }

        .purchase-id-box {
          .field-type-dropdown {
            .field-label {
              margin-bottom: 1.6rem;
            }
            .field-form {
              width: 23.5rem;
            }
          }
        }

        .period-box {
          .field-label {
            margin-bottom: 1.6rem;
          }
          .field-form {
            @include set-flex(row, center, center);
            justify-content: space-between;
            .radio {
              margin: 0 2.4rem 0 0.65rem;
              label {
                @include set-font(1.4rem, 400, $color-gray-01, 130%);
              }
              .custom {
                .input-box {
                  .datepicker {
                    width: 11rem;
                  }
                }
              }
            }
          }
        }
      }

      // .btn-back {
      //   position: absolute;
      //   display: block;
      //   width: 4.8rem;
      //   height: 4.8rem;
      //   background: rgba(#000, 0.4) asset-image('content_back.svg') no-repeat center / 1.6rem 2.7rem;
      //   border-radius: 50%;

      //   &:focus {
      //     outline: 2px solid $color-black;
      //   }
      // }
      .detail-top {
        .btn-back {
          top: 0;
          left: -2rem;
        }
      }

      .detail-content {
        position: relative;
        // padding: 2rem 0 0 8.4rem;
        .btn-back {
          top: 2rem;
          left: 1rem;
        }
      }

      .button-box {
        @include set-flex(row, center, center);
      }
    }
    // 웹접근성 수정 : main 밖으로 변경
    // .content-footer {
    //   @include set-flex(row, flex-end, center);
    //   padding: 0 4rem;
    //   height: 6rem;
    //   text-align: right;
    //   .footer_wrap {
    //     .footer_copy {
    //       @include set-font(1.2rem, 400, #333333, 110%);
    //       display: inline-block;
    //     }
    //     .footer_menu {
    //       display: inline-block;
    //       font-size: 0;
    //       li {
    //         display: inline-block;
    //         padding: 0 1.6rem;
    //         border-left: 1px solid rgba(0, 0, 0, 0.1);
    //         &:first-child {
    //           border-left: 0px;
    //           padding-left: 1.8rem;
    //         }
    //         a {
    //           @include set-font(1.2rem, 500, #333333, 110%);
    //           &:hover {
    //             text-decoration: underline;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }
  &.wide-padding {
    .content-box {
      .content-middle {
        padding: 0 3.2rem 0 1.6rem;
      }
    }
  }
  .content-footer {
    @include set-flex(row, flex-end, center);
    padding: 0 4rem;
    height: 6rem;
    text-align: right;
    .footer_wrap {
      .footer_copy {
        @include set-font(1.2rem, 400, #333333, 110%);
        display: inline-block;
      }
      .footer_menu {
        display: inline-block;
        font-size: 0;
        li {
          display: inline-block;
          padding: 0 1.3rem;
          // border-left: 1px solid rgba(0, 0, 0, 0.1);
          &:first-child {
            border-left: 0px;
            padding-left: 1.8rem;
          }
          a {
            @include set-font(1.2rem, 500, #333333, 110%);
            
            &:hover {
              text-decoration: underline;
            }

            &.open {
              background: asset-image('ic_open_fill.svg') no-repeat 100% center / 1.2rem auto;
              padding-right: 1.4rem;
            }
          }
        }
      }
    }
  }
}
// .content-container

@media screen and (max-width:1280px) {
  header {
    .features {
      display: none;
      & > li {
        .user-menu {
          .my-info-container {
            .my-info-contents {
              max-height: 22rem;
              overflow-y: auto;
            }
          }
        }
      }
    }
  }

  .content-container {
    &.wide-padding {
      padding-left:0rem;

      &.expand {
        padding-left:27.1rem;
      }
    }

    .content-box {
      .content-middle {
        &.wide-padding {
          padding-left: 3.2rem;
        }
        .content-middle-top {
          .btn-open-child {
            display: block !important;
          }
        }
      }
      
      .data-list-container {
        .grid {
          .element {
            width: calc(33.3% - 2rem);
            max-width: 100%;
          }
        }
      }
    }
  }
}

//200%
@media screen and (max-width:991px) {
  .content-container {
    .content-box {
      .content-top {
        .last-update {
          // @include set-flex(column, flex-start, flex-start);
          display: inline-block;
          position: relative;
          margin-bottom:0;
          padding-right: 1rem;
          .field-label {
            display: block;
          }
          .btn-update {
            position: absolute;
            top: .5rem;
            left: 100%;
          }
        }
      }
      .content-middle {
        .detail-top {
          .btn-back {
            position: static;
            margin-bottom: 1rem;
          }
        }
        .detail-content {
          padding: 0;
          // .top-info-box {
          //   padding-top: 8.8rem;
          // }
          .btn-back {
            top:2.4rem;
            left: 2rem;
            z-index: 2;
          }
        }
      }
    }
  }
  
  .content-container {
    &.wide-padding {
      // padding-left:8rem;

      &.expand {
        padding-left:8rem;
      }
      
      .content-box {
        .content-top {
          .top-left {
            display: block;
            
            .breadcrumb {
              &:before {
                display:none;
              }

              padding-left:5rem;
            }
          }
  
          .last-update {
            padding-left:5rem;
          }
        }    
      }
    } // &.has-child

    .content-box {
      .content-top {
        .top-left {
          .breadcrumb {
            .bread {
              font-size:1.4rem;
            }
          }
        }  
      } // .content-top

      .content-middle {
        .content-middle-box {
          .detail-top,
          .detail-bottom {
            padding-left: 0;
          }
        }
      } // .content-middle

      .data-list-container {
        .grid {
          .element {
            width: calc(50% - 2rem);
            max-width: 100%;
          }
        }
      } // .data-list-container
    }
  }
}


@media screen and (max-width: 767px) {
  .wrapper {
    .content-container {

      &.wide-padding {
        padding-left:0;
        padding-right:0;

        &.expand {
          padding-left:0;
          padding-right:0;
        }
      }

      &.expand {
        padding-left:0;
        padding-right:0;
      }
    }

    .side-bar:after {
      display:none;
    }
  }

  .content-container {
    .content-box {
      .data-list-container {
        .grid {
          .element {
            padding:1rem;
            width: calc(100% - 2rem);
            min-height: auto;
          }
        }
      }
    }
  }

  .content-container {
    padding: 0;
    padding-top: 8rem;

    &.expand {
      padding-left:0;
    }

    .content-box {
      .content-top {
        display: block;
        padding: 1rem 2rem;

        .top-left {
          display: block;

          h1 {
            @include set-flex(row, flex-start, center);
            @include set-font(1.9rem, 700, $color-gray-01);
            margin-right:0;
          }

          .breadcrumb {
            &:before {
              display: none;
            }

            .bread {
              font-size: 1.4rem;
            }
          }
        }
      }

      .content-middle {
        padding: 1rem 2rem !important;

        .content-middle-box {
          padding: 2rem;
          min-height: auto;

          .purchase-id-box {
            .field-type-dropdown {
              .field-form {
                width: 100%;
              }
            }
          }

          .period-box {
            .field-form {
              display: block;
              .field-box {
                display: block;
                width: 100%;
                .radio {
                  margin: 0 0 2.4rem 0;
                  label {
                    width: 100%;
                  }
                  .custom {
                    margin-top:1.5rem;
                    margin-left:0;
                    width: 100%;
                    .field-form  {
                      display: flex;
                      // @include set-flex(row, center, center);
                    }
                    .datepicker {
                      width: 10.2rem !important;
                    }
                  }
                }
              }
              .btn-view {
                width: 100%;
              }
            }
          }
        }
      }
      // 웹접근성 수정 : main 밖으로 변경
      // .content-footer {
      //   display: block;
      //   padding: 1.6rem 0;
      //   height: auto;
      //   text-align: center;
      //   .footer_menu {
      //     li {
      //       margin-top: .8rem;
      //     }
      //   }
      // }
    }
    .content-footer {
      display: block;
      padding: 1.6rem 0;
      height: auto;
      text-align: center;
      .footer_menu {
        li {
          margin-top: .8rem;
        }
      }
    }
  }
  .footer-container {
    .copyright {
      @include set-flex(row, center, center);
    }
  }
}

